import { useState } from "react";
import { decryptText } from "../../services/utilservices";
import { ExportExcelReceptionsInterface } from "../../services/receptions/interfaces/exportExcelReceptionsInterface";
import { fetchExportReceptions } from "../../services/receptions/fetchExportReceptions";

const useExportExcelReceptions = () => {
  const [loadingExport, setLoading] = useState(false);
  const [errorExport, setError] = useState<string | null>(null);

  const exportToExcel = async (filters: ExportExcelReceptionsInterface[] = []) => {
    setLoading(true);
    setError(null);

    if (filters.length === 0) {
      setError("No hay filtros disponibles.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetchExportReceptions(filters);

      if (response) {
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}${month}${day}`;

        let fieldName = decryptText(sessionStorage.getItem('species') || '');
        fieldName = fieldName + "_" + decryptText(sessionStorage.getItem('parentProducer')?.split("-")[0] || '');
        
        const fileName = `Recepciones_${fieldName}_${formattedDate}.xlsx`;
        const binaryData = [response];
        const fileURL = window.URL.createObjectURL(new Blob(binaryData, { type: response.type }));
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.setAttribute('download', fileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        console.log('Archivo descargado exitosamente:', fileName);
      } else {
        console.error("Error en la operación: No se pudo obtener el archivo.");
        setError("Error al descargar el archivo.");
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { exportToExcel, loadingExport, errorExport };
};

export default useExportExcelReceptions;
