import { decryptText } from "../utilservices";

export const fetchDashboard = async (filters: any = {}) => {
  const token = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).token || null
  const baseUrlenv = process.env.REACT_APP_API_URL;

  const urlBalance = `${baseUrlenv}DashBoard/Balance`;
  const urlGraficoRecepcion = `${baseUrlenv}DashBoard/GraficoRecepcion`;
  const urlGraficoSegregacion = `${baseUrlenv}DashBoard/GraficoSegregacion`;
  const urlDatosCalibre = `${baseUrlenv}DashBoard/GraficoCalibre`;


  const productoresIds = JSON.parse(decryptText(sessionStorage.getItem('prod') || "") || "[]");
  const perfilId = JSON.parse(decryptText(sessionStorage.getItem("data")!)).id;
  const localProfile = JSON.parse(decryptText(sessionStorage.getItem("data")!)).perfilDescripcion;
  let codigo=0;
  if(localProfile!="Productor"){
    codigo=filters.productorPadreId
  }
  else{
    if(filters.productorPadreId==null){
      codigo=productoresIds[0];
    }
    else{
      codigo=filters.productorPadreId
    }
    
  }
  const body = {
    productorId: codigo || null,
    codProductorHijoId: filters.productorId || null,
    temporadaId: filters.temporadaId || null,    
    especieId: filters.especieId || null,
    variedadId: filters.variedadId || null,
    perfilId: perfilId || null,
  };

  const body2 = {
    productorPadreId: codigo|| null,
    codProductorHijoId: filters.productorId || null,
    temporadaId: filters.temporadaId || null,
    especieId: filters.especieId || null,
    variedadId: filters.variedadId || null,
  };

  try {
    // Realizar todas las solicitudes de manera concurrente
    const [responseBalance, responseGrafico, responseSegregacion, responseCalibre] = await Promise.all([
      fetch(urlBalance, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }),
      fetch(urlGraficoRecepcion, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(body2),
      }),
      fetch(urlGraficoSegregacion, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(body2),
      }),
      fetch(urlDatosCalibre, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(body2),
      }),

    ]);

    // Verificar si alguna de las respuestas es incorrecta
    if (!responseBalance.ok || !responseGrafico.ok || !responseSegregacion.ok || !responseCalibre.ok) {
      throw new Error("Error in API request");
    }

    // Obtener los datos de todas las respuestas
    const dataBalance = await responseBalance.json();
    //console.log("Balance Data:", dataBalance);

    const dataGrafico = await responseGrafico.json();
    //console.log("Grafico Recepcion Data:", dataGrafico);

    const dataSegregacion = await responseSegregacion.json();
    /*const dataCalibre = await responseCalibre.json(); */
    //console.log("Grafico Segregacion Data:", dataSegregacion);
    const dataCalibre = await responseCalibre.json();

    // Combinar los datos en un solo objeto
    const combinedData = {
      balance: dataBalance?.someNestedProperty || dataBalance,
      graficoRecepcion: dataGrafico?.someNestedProperty || dataGrafico,
      graficoSegregacion: dataSegregacion?.someNestedProperty || dataSegregacion,
      graficoCalibre: dataCalibre?.someNestedProperty || dataCalibre
    };


    return combinedData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
