import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useEffect } from 'react';
import Footer from './modules/components/footer';
import Navbar from './modules/components/navbar';
import { routes } from './routesConfig';
import Breadcrumbs from "./modules/components/breadcrumbs";
import { AuthProvider } from './AuthContext'; 
import { SnackbarProvider } from './context/SnackbarContext'; // Importa tu SnackbarProvider
import InactivityTimeout from './modules/InactivityTimeout';
const App: React.FC = () => {

  useEffect(() => {
    if (window.top !== window.self) {
      console.warn("Blocked iframe loading for security reasons.");
      document.body.innerHTML = '';
    }
  }, []);

  const renderRoutes = (routesArray: any[]) => {
    return routesArray.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={<route.Component />}
      >
        {route.children && renderRoutes(route.children)}
      </Route>
    ));
  };

  return (
    <div className='App'>
      <Router>
        <AuthProvider> {/* Envuelve todo tu contenido con AuthProvider */}
          <SnackbarProvider> {/* Envuelve también con SnackbarProvider */}
          <InactivityTimeout /> 
            <Navbar />
            <Breadcrumbs />
            <div className={`px-6`}>
              <hr />
            </div>
            <main className='text-font-black'>
              <Routes>
                {renderRoutes(routes)}
              </Routes>
            </main>
            <Footer />
          </SnackbarProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
