import {callApi, decryptText, fetchIP} from "../utilservices";

export const updateTermsUser = async (isAccepted: boolean): Promise<boolean> => {
    let userPayload = {
        id: JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).id,
        aceptaTerminos: isAccepted,
        ip: await fetchIP()
      }
    
      try {
        await callApi({
          url: `Usuarios/TerminoyCondiciones`,
          method: 'POST',
          data: userPayload,
          headers: {
            "Authorization": `Bearer ${JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).token}`
          }
        });

        return true;
      } catch (error) {
        throw Error('Error al actualizar los términos y condiciones.')
      }
};
