import { Navigate } from 'react-router-dom';
import React from 'react';
import { decryptText } from '../services/utilservices';

interface AuthGuardProps {
  children: React.ReactNode;
}

function AuthGuard({ children }: AuthGuardProps) {
  const isAuthenticated = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).token || null; 

  // Si el usuario ya está autenticado, redirigir al dashboard
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  // Si no está autenticado, permitir acceso a la ruta pública
  return <>{children}</>;
}

export default AuthGuard;
