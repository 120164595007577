import React, { useState } from 'react';

const PreguntasFrecuentes = () => {
  const preguntas = [
    {
      pregunta: '¿Cómo puedo acceder al portal "Productores"?',
      respuesta: (
        <>
          Para acceder al portal "Productores" de David del Curto, sigue estos pasos:
          <ol className="list-decimal ml-5 mt-2">
            <li>
              <b>Acceso al portal:</b> Ingresa a la página oficial del portal en{" "}
              <a
                href="https://productores.ddc.cl"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                https://productores.ddc.cl
              </a>.
            </li>
            <li>
              <b>Inicio de sesión:</b> En la página principal, introduce tu nombre de usuario y contraseña en los campos correspondientes.
            </li>
            <li>
              <b>Recuperación de contraseña:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Si has olvidado tu contraseña, haz clic en el enlace "¿Olvidaste tu contraseña?" ubicado en la página de inicio de sesión.
                </li>
                <li>
                  Serás redirigido a una página donde deberás ingresar tu dirección de correo electrónico asociada a tu cuenta.
                </li>
                <li>
                  Tras enviar tu correo electrónico, recibirás un mensaje con un enlace para restablecer tu contraseña.
                </li>
                <li>
                  Haz clic en el enlace proporcionado en el correo y sigue las instrucciones para establecer una nueva contraseña.
                </li>
              </ul>
            </li>
            <li>
              Si aún no tienes acceso al portal, en la misma página de inicio puedes solicitarlo haciendo clic en "Solicítalo aquí".
            </li>
          </ol>
          Para cualquier inconveniente o duda adicional, puedes contactar al soporte técnico de David del Curto para recibir asistencia personalizada:
          <br />
          <a
            href="mailto:soporteTI@ddc.cl"
            className="text-blue-500 underline"
          >
            soporteTI@ddc.cl
          </a>.
        </>
      ),
    },
    {
      pregunta: '¿Qué información puedo ver en el portal sobre mi fruta?',
      respuesta: (
        <>
          En el portal "Productores" podrás acceder a información detallada sobre tu fruta, organizada de la siguiente manera:
          <ol className="list-decimal ml-5 mt-2">
            <li>
              <b>Balance de Masa:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>Muestra un resumen general de las recepciones, procesos y exportaciones. Podrás visualizar:</li>
                <ul className="list-disc ml-5">
                  <li>Recepciones totales (en kilogramos).</li>
                  <li>Materia Prima no conforme (si corresponde).</li>
                  <li>Procesos concluidos, incluyendo el porcentaje procesado.</li>
                  <li>Exportaciones realizadas y su proporción respecto a lo procesado.</li>
                  <li>
                    Clasificación Comercial, desglosando los kilos disponibles en categorías como "Comercial" y "Pre-Calibre".
                  </li>
                </ul>
              </ul>
            </li>
            <li>
              <b>Detalle de Procesos:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Incluye un desglose de la fruta procesada, indicando categorías como:
                </li>
                <ul className="list-disc ml-5">
                  <li>Exportados, calibrados, comerciales, pre-calibres, sobre-calibres, industriales, desechos y mermas.</li>
                  <li>
                    Cada categoría muestra los kilos totales y su porcentaje dentro del total procesado.
                  </li>
                </ul>
              </ul>
            </li>
            <li>
              <b>Recepciones Semanales:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Un gráfico que detalla la cantidad de fruta recibida semanalmente, mostrando tendencias claras de las semanas activas.
                </li>
              </ul>
            </li>
            <li>
              <b>Segregación de la Producción:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Visualiza la calidad de la producción segregada en categorías como Premium, Estándar y Categoría 2.
                </li>
                <li>
                  Puedes comparar los porcentajes entre tu producción y los estándares de David del Curto.
                </li>
              </ul>
            </li>
            <li>
              <b>Curva de Calibre de Producción:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Indica el calibre de la fruta producida, con categorías específicas como Giant, 4J, 3J, entre otras.
                </li>
                <li>
                  Podrás analizar el porcentaje correspondiente a cada calibre en relación a tu producción y a los estándares de la empresa.
                </li>
              </ul>
            </li>
          </ol>
          Toda esta información te permitirá realizar un seguimiento detallado del estado y calidad de tu fruta a lo largo del proceso de exportación.
          <br />

        </>
      ),
    },
    {
      pregunta: '¿Qué significa "Balance de Masa" y cómo lo interpreto?',
      respuesta: (
        <>
          El "Balance de Masa" es una herramienta clave dentro del portal "Productores" que te permite visualizar el estado general de tu fruta a lo largo del proceso de exportación. Representa un resumen consolidado de las distintas etapas del manejo de la fruta, organizado de la siguiente manera:
          <ol className="list-decimal ml-5 mt-2">
            <li>
              <b>Recepciones:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>Indica el total de fruta (en kilogramos) que ha sido recepcionada durante la temporada en curso.</li>
              </ul>
            </li>
            <li>
              <b>Procesos:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Muestra la cantidad de fruta que ha sido procesada y el porcentaje que esto representa en relación al total recibido.
                </li>
                <li>
                  Incluye desgloses adicionales para especificar cuánto de este total ha sido exportado, calibrado, clasificado como comercial o destinado a pre-calibres, desechos, entre otros.
                </li>
              </ul>
            </li>
            <li>
              <b>Exportaciones:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Detalla la cantidad de fruta lista para exportación, indicando tanto los kilos como el porcentaje en relación al total procesado.
                </li>
              </ul>
            </li>
            <li>
              <b>Clasificación Comercial:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>
                  Presenta un desglose adicional de la fruta clasificada como comercial, incluyendo categorías como:
                </li>
                <ul className="list-disc ml-5">
                  <li>Comercial.</li>
                  <li>Pre-Calibre.</li>
                  <li>Sobre-Calibre e Industrial.</li>
                </ul>
              </ul>
            </li>
          </ol>
          <b>¿Cómo interpretar esta información?</b>
          <p className="mt-2">
            El Balance de Masa te da una visión rápida y clara del rendimiento de tu fruta en cada etapa del proceso. Por ejemplo:
          </p>
          <ul className="list-disc ml-5 mt-2">
            <li>Puedes identificar cuánta fruta está lista para exportación.</li>
          </ul>
          <p className="mt-2">
            Esta herramienta te ayuda a evaluar el estado global de tu producción y a tomar decisiones informadas para mejorar tus próximos envíos.
          </p>
        </>
      ),
    },
    {
      pregunta: '¿Cómo se clasifica la fruta según su calidad?',
      respuesta: (
        <>
        La segregación de la fruta según su calidad es un proceso 
        clave que clasifica la fruta recepcionada en diferentes categorías
        basadas en estándares de calidad. Estas categorías permiten evaluar el potencial
        de exportación, comercialización y otras destinaciones de la fruta, por tanto la 
        clasificación es la aptitud de la fruta en cuanto a condición y calidad dependiendo 
        de la exigencia del mercado destino.
        </>
      ),
    },
    {
      pregunta: '¿Qué puedo hacer si detecto un error en el reporte de calidad de mi fruta?',
      respuesta: (
        <>
Procedimiento para notificar errores y recibir soporte.
        </>
      ),
    },
    {
      pregunta: '¿Con qué frecuencia se actualizan los reportes de calidad y las recepciones?',
      respuesta: (
        <>
        Información sobre la periodicidad de las
         actualizaciones en el portal.
        </>
      ),
    },
    {
      pregunta: '¿Cómo puedo visualizar cuánta fruta se ha recibido por semana?',
      respuesta: (
        <>
          En el portal "Productores", puedes acceder a la información de las recepciones semanales de tu fruta a través de un gráfico interactivo que muestra la cantidad de fruta recepcionada cada semana, en kilogramos. 
          <br />
          <br />
          <b>Pasos para visualizar la información:</b>
          <ol className="list-decimal ml-5 mt-2">
            <li>
              <b>Ubicación del gráfico:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>Ve a la sección de "Recepciones Semanales", disponible en el dashboard principal del portal.</li>
              </ul>
            </li>
            <li>
              <b>Información mostrada:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>El gráfico detalla la cantidad de fruta recibida durante cada semana de la temporada en curso.</li>
                <li>En el eje horizontal se encuentran las semanas (numeradas) y en el eje vertical, los kilogramos de fruta recepcionada.</li>
              </ul>
            </li>
            <li>
              <b>Lectura del gráfico:</b>
              <ul className="list-disc ml-5 mt-2">
                <li>Las barras azules representan los kilos reales de fruta recepcionados durante la semana correspondiente.</li>
                <li>Si hay semanas con mayor o menor recepción, puedes identificar fácilmente las tendencias de entrega.</li>
              </ul>
            </li>
          </ol>
          <b>¿Por qué es útil esta información?</b>
          <p className="mt-2">
            El gráfico de recepciones semanales te permite:
          </p>
          <ul className="list-disc ml-5 mt-2">
            <li>Generar indicadores de gestión que ayudan a monitorear los kilos recepcionados por semana.</li>
            <li>Analizar los resultados en comparación con años anteriores para mejorar la gestión de tu producción.</li>
          </ul>
          <p className="mt-2">
            Si necesitas descargar o analizar esta información más detalladamente, puedes combinarla con otros reportes disponibles en el portal.
          </p>
        </>
      ),
    },
    {
      pregunta: '¿Qué significa la información de tamaño de la fruta en los reportes?',
      respuesta: (
        <>
          En los reportes del portal "Productores", la información de tamaño de la fruta se 
          presenta mediante curvas de calibre, que permiten clasificar la producción en base a las dimensiones de los frutos. 
          Esta clasificación es crucial,
           ya que el tamaño de la fruta es un factor determinante para su destino comercial y precio en el mercado.
        </>
      ),
    },
    {
      pregunta: '¿Qué hago si no puedo ver toda mi fruta en el sistema?',
      respuesta: (
        <>
          La información del portal se actualiza en horarios establecidos:{" "}
          <ul className="list-disc ml-5 mt-2">
            <li>05:00 horas.</li>
            <li>13:00 horas.</li>
            <li>17:00 horas.</li>
            <li>21:00 horas.</li>
          </ul>
          Por lo que tu fruta debe verse en el portal dentro de alguna de esas 
          ventanas diarias, sí la discrepancia persiste alguna comunícate con nosotros 
          de inmediato al correo {" "}
          <a href="mailto:soporteTI@ddc.cl" className="text-blue-500 underline">
            soporteTI@ddc.cl
          </a>.
        </>
      ),
    },
    {
      pregunta: '¿Cómo sé si mis procesos de exportación están concluidos?',
      respuesta: (
        <>
          Aparecen reflejados en la sección de procesos del portal.
        </>
      ),
    },
    {
      pregunta: '¿Puedo descargar los reportes desde el portal?',
      respuesta: (
        <>
          Sí, en las secciones de recepciones y procesos puedes filtrar y descargar reportes en formato Excel.
        </>
      ),
    },
  ];


  const [abierto, setAbierto] = useState(null);

  const toggleAcordeon = (index) => {
    setAbierto(abierto === index ? null : index);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold text-center mb-4">Preguntas Frecuentes</h1>

      {preguntas.map((item, index) => (
        <div key={index} className="bg-white p-4 mb-4 rounded-lg shadow-md">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleAcordeon(index)}
          >
            <h2 className="font-semibold text-lg">{item.pregunta}</h2>
            <svg
              className={`transform transition-transform ${abierto === index ? "rotate-180" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </div>
          {abierto === index && (
            <div className="text-sm text-gray-700 mt-2">{item.respuesta}</div>
          )}
        </div>
      ))}

      <div className="mt-4 text-center">
        <p>
          Si tu pregunta no está aquí, por favor contáctanos a{" "}
          <a href="mailto:soporteTI@ddc.cl" className="text-blue-500 underline">
            soporteTI@ddc.cl
          </a>.
        </p>
      </div>
    </div>
  );
};

export default PreguntasFrecuentes;
