import svg from '../../../assets/icons/question-mark-circled.svg'
import {useState} from "react";
import Modal from "../modal";
import { useNavigate  } from "react-router-dom";
const Footer = () => {
  const [modalContent, setModalContent] = useState<React.ReactNode | string>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const openModal = (content: React.ReactNode | string) => {
    setModalContent(content);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/preguntasfrecuentes"); // Redirige a la sección de ayuda
  };
  return (
    <footer className="bg-transparent">
      <div className="flex flex-col md:flex-row justify-between items-center my-6 mx-6 md:mx-14 space-y-4 md:space-y-0">
        <ul className="flex flex-col md:flex-row gap-4 md:gap-6 items-center">
          <li>
            <a
              onClick={() =>
                openModal(
                  <div>
                    Si tienes algún problema, comunícate a través de nuestro correo electrónico{" "}
                    <span className="text-ddc-blue">soporteTI@ddc.cl</span>
                  </div>
                )
              }
              className="flex flex-row gap-2 text-ddc-blue font-[300] cursor-pointer"
            >
              <img src={svg} alt="help" className="w-4 h-4" />
              Ayuda
            </a>
          </li>
          <li>
          <a
            onClick={handleRedirect}
            className="flex flex-row gap-2 text-ddc-blue font-[300] cursor-pointer"
          >
              Preguntas Frecuentes
            </a>
          </li>
        </ul>
        <div className="text-sm text-ddc-blue text-center md:text-right">
          <span>&#169; Derechos Reservados David del Curto 2024</span>
        </div>
      </div>
      <Modal
        isVisible={isModalVisible}
        onClose={closeModal}
        title="Ayuda y Soporte"
        content={modalContent}
      />
    </footer>
  );
  
};
  
export default Footer;