import { useState, useEffect, useRef } from "react";
import { isEqual } from "lodash";
import { fetchDashboard } from "../../services/dashboard/fetchDashboard";
import { decryptText } from "../../services/utilservices";

const useDashboard = (filters: any = {}) => {
  const [dashboardData, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const prevFilters = useRef(filters);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (isEqual(prevFilters.current, filters)) {
      return;
    }

    prevFilters.current = filters;

    const loadDashboardData = async () => {

      const savedFilters = JSON.parse(decryptText(sessionStorage.getItem('filters') || '{}'));
      
      if (filters != null && (filters.especieId == savedFilters.speciesId && filters.productorId == savedFilters.producerId && filters.productorPadreId == savedFilters.parentProducerId && filters.temporadaId == savedFilters.seasonId && filters.variedadId == savedFilters.varietyId)) {
        setLoading(true);
        setError(null);
        try {
          const result = await fetchDashboard(filters);
          setData(result);
        } catch (error: any) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    loadDashboardData();
  }, [filters]);

  return { dashboardData, loading, error };
};

export default useDashboard;
