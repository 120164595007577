import React, { useEffect, useState } from 'react';
import { updateTermsUser } from '../../../services/users/users';
import { useSnackbar } from '../../../context/SnackbarContext';
import { CogIcon } from '@heroicons/react/20/solid';

interface Props {
  handleAccept: () => void;
  handleReject: () => void;
}

const TermsConditions: React.FC<Props> = ({ handleAccept, handleReject }) => {
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden'; 
    return () => {
      document.body.style.overflow = 'unset'; 
    };
  }, []);

  const handleUpdateTerms = async (isAccepted: boolean) => {
    try {
      setLoading(true);
      let response: boolean = await updateTermsUser(isAccepted);
      if (response)
        handleAccept();
      
    } catch (error:any) {
      showMessage(error);
    }

    setLoading(false);
  };
  return (
    <div className="terms-modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Términos y Condiciones</h2>
        </div>

        <div className="modal-body">
          <p>
            Bienvenido al <b>Portal de Productores</b>, administrado por <b>DDC</b>. Este documento regula el uso
            del portal y describe cómo recopilamos, usamos y protegemos la información personal y
            comercial de nuestros productores, en conformidad con la normativa chilena vigente,
            incluyendo la Ley 19.628 sobre Protección de la Vida Privada.
          </p>

          <p>
            Al registrarte y utilizar este portal, aceptas los términos y condiciones aquí descritos,
            así como nuestra política de privacidad.
          </p>

          <h3>1. Términos y Condiciones de Uso</h3>
          <h4>1.1. Objetivo del Portal</h4>
          <p>
            Este portal ha sido diseñado para facilitar la gestión de productores de frutas destinadas a la
            exportación. Las principales funcionalidades incluyen:
          </p>
          <ol>
            <li>Registro y actualización de información del productor.</li>
            <li>Carga de documentos requeridos para procesos de certificación y exportación.</li>
            <li>Gestión de pedidos y solicitudes.</li>
            <li>Comunicación directa con la empresa exportadora.</li>
          </ol>

          <h4>1.2. Obligaciones del Productor</h4>
          <p>Al utilizar el portal, el productor se compromete a:</p>
          <ol>
            <li>Proporcionar información verídica, completa y actualizada.</li>
            <li>Subir documentos legales y válidos que cumplan con los requisitos exigidos por las
            normativas chilenas y del país de destino.</li>
            <li>Usar el portal exclusivamente para los fines definidos en este documento.</li>
            <li>Respetar las normativas del Servicio Agrícola y Ganadero (SAG) y otras regulaciones aplicables
            a la exportación de productos agrícolas.</li>
          </ol>

          <h4>1.3. Prohibiciones</h4>
          <p>El productor tiene estrictamente prohibido:</p>
          <ol>
            <li>Utilizar el portal para actividades ilícitas o fraudulentas.</li>
            <li>Compartir sus credenciales de acceso con terceros.</li>
            <li>Subir información o documentos falsos.</li>
            <li>Realizar acciones que puedan dañar la seguridad o el funcionamiento del sistema.</li>
          </ol>

          <h4>1.4. Limitación de Responsabilidad</h4>
          <p>DDC no será responsable de:</p>
          <ol>
            <li>Daños ocasionados por el uso indebido del portal por parte del productor.</li>
            <li>Errores en la información suministrada por el productor.</li>
            <li>Interrupciones en el servicio debido a mantenimientos programados, fallos técnicos o eventos de fuerza mayor.</li>
          </ol>

          <h4>1.5. Modificaciones a los Términos</h4>
          <p>DDC se reserva el derecho de modificar estos términos y condiciones. Cualquier cambio será notificado mediante correo electrónico y un aviso en el portal. El productor deberá aceptar los nuevos términos para continuar utilizando el sistema.</p>

          <h3>2. Política de Privacidad y Manejo de Información Personal</h3>

          <h4>2.1. Información Recopilada</h4>
          <p>Recopilamos los siguientes datos del productor:</p>
          <ol>
            <li>Datos de la empresa: Razón social, RUT, dirección comercial, contacto principal.</li>
            <li>Datos del representante legal: Nombre, cédula de identidad, correo electrónico, teléfono.</li>
            <li>Información operativa: Tipos de fruta ofrecida, certificaciones, volúmenes disponibles, registros de calidad.</li>
            <li>Documentos requeridos: Certificados sanitarios, informes fitosanitarios, permisos de exportación.</li>
          </ol>

          <h4>2.2. Finalidad del Tratamiento</h4>
          <p>La información recopilada se utilizará para:</p>
          <ol>
            <li>Gestionar el registro y validación de productores.</li>
            <li>Cumplir con los requisitos legales y regulatorios para la exportación de frutas.</li>
            <li>Facilitar la comunicación y los acuerdos comerciales.</li>
            <li>Emitir reportes requeridos por entidades regulatorias, como el Servicio Agrícola y Ganadero (SAG).</li>
          </ol>

          <h4>2.3. Compartición de Datos</h4>
          <p>Los datos personales y comerciales serán compartidos únicamente cuando sea necesario para:</p>
          <ol>
            <li>Cumplir con normativas legales.</li>
            <li>Gestionar procesos de exportación ante autoridades chilenas o socios comerciales.</li>
          </ol>

          <h4>2.4. Retención y Protección de Datos</h4>
          <p>Los datos serán almacenados durante el tiempo necesario para cumplir con los fines establecidos o según lo exija la normativa chilena. Implementamos medidas técnicas y administrativas para proteger la información, como encriptación, firewalls y acceso restringido.</p>

          <h4>2.5. Derechos del Productor</h4>
          <p>De acuerdo con la Ley 19.628, el productor tiene derecho a:</p>
          <ol>
            <li>Acceder a sus datos personales almacenados.</li>
            <li>Solicitar la rectificación de datos incorrectos o desactualizados.</li>
            <li>Oponerse al tratamiento de datos para fines no autorizados.</li>
            <li>Solicitar la eliminación de sus datos cuando ya no sean necesarios.</li>
          </ol>

          <h3>3. Consentimiento y Aceptación</h3>
          <p>El productor debe aceptar estos términos y condiciones antes de utilizar el portal. Este consentimiento será registrado electrónicamente con la fecha, hora y versión de los términos aceptados.</p>
          
          <h4>Texto de Aceptación:</h4>
          <p>“Declaro que he leído y acepto los Términos y Condiciones de Uso, así como la Política de Privacidad y Manejo de Información Personal de este portal. Comprendo que mi información será tratada de acuerdo con la normativa chilena vigente.”</p>

          <h3>4. Actualizaciones y Notificaciones</h3>
          <p>Cualquier actualización a estos términos será notificada mediante correo electrónico y un aviso destacado en el portal. La continuación del uso del portal implicará la aceptación de las nuevas condiciones.</p>

          <h3>5. Contacto</h3>
          <p>Para consultas o solicitudes relacionadas con estos términos, puedes contactarnos en:</p>
          <ol>
            <li>Correo electrónico: [info.productores@ddc.cl]</li>
          </ol>
          <p>DDC agradece tu confianza y compromiso con la calidad de nuestras exportaciones.</p>
        </div>

        <div className="modal-actions">
          <button className="buttonAccept" onClick={() => handleUpdateTerms(true)}>Aceptar</button>
          <button className="buttonCancel" onClick={handleReject}>Rechazar</button>
        </div>
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="text-white flex items-center space-x-3">
            <CogIcon className="w-8 h-8 animate-spin" /> {/* Ícono de coche girando */}
            <span className="text-xl">Cargando...</span>
          </div>
        </div>
      )}      
    </div>
  );
};

export default TermsConditions;
