import {FilterByInterface, FilterOptionInterface} from "../../modules/components/FilterBy/interfaces/filterByInterface";
import { decryptText } from "../utilservices";

export const fetchProcesses = async (filterBy:FilterByInterface | null) => {
  const token = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).token || null
 // console.log('paso')
  if (!token) {
    console.error("No se encontró el token de autenticación.");
    throw new Error("Token de autenticación no encontrado.");
  }

  const baseUrlenv = process.env.REACT_APP_API_URL;
  const url = `${baseUrlenv}Proceso/Listar`;

  let filters = decryptText(sessionStorage.getItem('filters') || "")
  if (!filters) {
    console.warn("No se encontraron filtros en sessionStorage, se usarán los filtros por defecto.");
  }

  // Establecer valores predeterminados para las propiedades faltantes
  let parsedFilters: FilterByInterface = {
    pageNumber: 1, // Página inicial
    pageSize: 10,  // Tamaño de página por defecto
    paginationFilters: [], // Filtros de paginación vacíos por defecto
    parentProducerId: null,
    seasonId: null,
    speciesId: null,
    varietyId: null,
  };

  // Si existen filtros en sessionStorage, parsearlos
  if (filters) {
    try {
      parsedFilters = JSON.parse(filters);
    } catch (error) {
      console.error("Error al parsear los filtros de sessionStorage:", error);
      throw new Error("No se pudieron procesar los filtros.");
    }
  }

  // Crear el cuerpo de la solicitud
  const body = {
    pagina: filterBy?.pageNumber || 1,
    cantidadRegistros: filterBy?.pageSize || 10,
    paginationFilters: filterBy?.paginationFilters, // Filtros de paginación
  };
  let prod = decryptText(sessionStorage.getItem('prod') || "");
  let parsedProd = prod ? JSON.parse(prod) : [];
  const perfil = JSON.parse(decryptText(sessionStorage.getItem("data")!)).perfilDescripcion;
  
  if(parsedProd.length>0 || perfil != "Productor"){ 
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      console.error("Error en la solicitud:", errorMessage);
      throw new Error("Error en la solicitud de la API.");
    }

    const data = await response.json();
    return data; // Retorna los datos obtenidos de la API
  } catch (error) {
    console.error("Error:", error);
    throw error; // Lanza el error para que lo maneje el componente que llama a esta función
  }
}
};

/*export const fetchProcesses = async (filterBy: FilterByInterface | null) => {
  const token = sessionStorage.getItem('token');
  const baseUrlenv = process.env.REACT_APP_API_URL;

  // Base URL para la API
  const baseUrl = `${baseUrlenv}Proceso`;

  // Definir los valores por defecto para pageNumber y pageSize
  const pageNumber = filterBy?.pageNumber || 1;
  const pageSize = filterBy?.pageSize || 10;

  // Crear la URL con los parámetros necesarios
  let url = `${baseUrl}/${pageNumber}/${pageSize}`;
 // console.log( filterBy)
  if (filterBy?.paginationFilters && filterBy.paginationFilters.length > 0) {
    filterBy?.paginationFilters.forEach(filterElement => {
      const filtrarPor = filterElement.filtrarPor || 'null';  // Si filtrarPor es vacío, se usa 'null'
      const textoBusqueda = filterElement.textoBusqueda || 'null';  // Si textoBusqueda es vacío, se usa 'null'
    
      // Imprimir las variables en la consola para verificar su valor
      //console.log('filtrarPor:', filtrarPor, 'textoBusqueda:', textoBusqueda);
    
      // Agregar los filtros a la URL
      url += `/${filtrarPor}/${textoBusqueda}`;
    })
} else {
  url += `/null/null`;
}
  

  try {
    const response = await fetch(url, {
      method: "GET",  // Cambiar a GET
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    });

    // Verificar si la respuesta es válida
    if (!response.ok) {
      throw new Error("Error in API request");
    }

    // Parsear la respuesta JSON
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};*/
