import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  chartData: ChartData<"doughnut">;
}

const options: ChartOptions<"doughnut"> = {
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
  cutout: "90%",
  circumference: 180,
  rotation: -90,
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({ chartData }) => {
  const total = chartData.datasets[0].data.reduce((a, b) => a + b, 0);
  const cumplimiento = chartData.datasets[0].data[0];
  const porcentajeCumplimiento = total > 0 ? ((cumplimiento / total) * 100).toFixed(0) : "0";
  
  return (
    <div className="flex flex-col items-center justify-center w-full" style={{ marginTop: "-50px" }}>
      <Doughnut data={chartData} options={options} className="h-full w-full" />
      <div style={{ marginTop: "-110px" }} className="text-center">
        <p className="text-xl font-bold text-gray-500">Cajas Base</p>
        <p className="text-3xl font-bold mb-2">{Math.floor(cumplimiento).toLocaleString("es-ES")}</p>
        <div className="flex justify-center items-center">
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: "#00BFFF",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <p style={{ margin: 0 }}>
            <span className="font-bold mr-4">{porcentajeCumplimiento}%</span>{" "}
            <span className="text-gray-500">Cumplimiento</span>
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between items-center mt-3">
        <p className="font-bold text-gray-500">Total Contratado</p>
        <p className="font-bold text-xl">{total.toLocaleString("es-ES")}</p>
      </div>
    </div>
  );
};

export default DoughnutChart;
