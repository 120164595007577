import { callApi } from "../utilservices";
import { ExportExcelProcessesInterface } from "./interfaces/exportExcelProcessesInterface";

export const fetchExportProcesses = async (filters: ExportExcelProcessesInterface[]) => {
  const url = `Proceso/Consolidado`;

  try {
    const response = await callApi({
      url: url,
      method: 'POST',
      data: {
        paginationFilters: filters,
      },
      responseType: 'blob' as 'json', 
    });

    if (response instanceof Blob) {
      return response; 
    } else {
      console.error('No se pudo descargar el archivo.');
    }
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  }
};
