import { useState } from "react";
import {callApi, decryptText} from "../../../services/utilservices";

interface userData {
  contrasena:string,
  contrasenaGenerica:boolean,
  correo:string,
  estado:number,
  estadoDescripcion:string,
  id:number,
  nombre:string,
  perfil:number,
  perfilDescripcion:string,
  rut:string,
  telefono:any,
  productores: any
}

const useData = () => {
  const [data, setData] = useState<userData | null>(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const id= JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).id || null;
  
  const handleData = async () => {
    try {
      const result = await callApi({
        url: `Usuarios/${id}`, // Cambia esto a la ruta correcta para obtener el usuario
        method: 'GET',
      });
      setData(result);

    } catch (error) {
      console.error(error);
    }
  };

  return { data, handleData };
};

export default useData;
