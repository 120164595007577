import {callApi} from "../../../services/utilservices";
import {useState} from "react";
import { useSnackbar } from '../../../context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
const usePassword = () => {
  const [passwordResponse, setData] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { showMessage } = useSnackbar(); // mensaje 
  const navigate = useNavigate();
  const handlePassword = async (
    usuarioId:any,
    contrasena:any,
    newPassword:any,
    confirmPassword:any,
    correo:any,
    nombre:any,
    productores:any,
    rut:any,
    telefono:any,
    perfil: any
  ) => {
    
    try {
      const response = await callApi({
        url: `${apiUrl}Usuarios`,
        method: 'PUT',
        data: {
          id: usuarioId,
          correo: correo,
          contrasena:newPassword,
          confirmaContrasena: confirmPassword,
          nombre:nombre,
          productores: Array.isArray(productores) ? productores : [0],
          perfil: perfil,
          rut: rut,
          telefono: telefono
        },
      });

      setData(response);
      if(response){
        showMessage('Se actualizó la constraseña', 'success'); 
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('rol');
        sessionStorage.removeItem('nombre');
        setTimeout(() => {
          navigate("/login")
        }, 2000);
        
      }
      else{
        showMessage('Ha ocurrido algún error' + response.mensaje, 'error'); 
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { passwordResponse, handlePassword };
};

export default usePassword;