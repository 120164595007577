import React, { useState, useRef, useEffect } from "react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

interface Option {
  name: string;
  kilograms: number;
  percent: number;
}

interface ICustomDropdown {
  description: string;
  childrenOptions: Option[];
  icon?: string;
  SVGIcon?: React.ComponentType;
}

const DropdownRowInfo: React.FC<ICustomDropdown> = ({
  description, childrenOptions, icon, SVGIcon
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Crear una referencia para el dropdown

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Cierra el dropdown si se hace clic fuera de él
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // Agrega el evento de clic al documento

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Limpia el evento al desmontar el componente
    };
  }, []);

  const colors: { [key: string]: string } = {
    "Exportados": "#00c7b1", 
    "Calibrado": "#58bfe9",    
    "Comercial": "#3b00ed",    
    "Pre-Calibre": "#9c27b0",  
    "Sobre-Calibre": "#d81b60", 
    "Industrial": "#ff9800",   
    "Desecho": "#3dcd7e",      
    "Merma": "#ffd900"         
  };
  const colorsrandom:(string)[] = ["#00c7b1", "#58bfe9", "#3b00ed", "#9c27b0", "#d81b60", "#ff9800", "#3dcd7e", "#ffd900"];

  const randomColor = () => {
    return colorsrandom[Math.floor(Math.random()*colorsrandom.length)];
  }
  const getColorForItem = (itemName: string) => {
    return colors[itemName] || randomColor(); 
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-[#C2EDFF] rounded-custom border-gray-300 shadow-sm font-bold text-left cursor-pointer flex justify-between items-center py-[2px]"
      >
        {/*<img src={icon} alt="" width={45} height={45}/>*/}
        <div className={`icon-chart-blue h-[45px] w-[45px]`}>
          {SVGIcon? <SVGIcon/> : <img src={icon} alt="" width={45} height={45} />}
        </div>
        <span className="font-semibold text-[14px] pr-2 ml-2 mr-5">{description}</span>
        {
        <ChevronDownIcon
          className="group pointer-events-none absolute top-4 right-2 size-4 text-font-blue-gray"
          aria-hidden="true"
        />
        }
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-[350px] bg-white rounded-md shadow-lg z-10 py-2">
          <div className="px-4 pt-1 text-center font-bold text-xl">
            <h5>{description}</h5>
          </div>
          <div className="px-6 py-3">
            {childrenOptions.map((item, index) => (
              <div key={index} className="flex flex-col">
                <div className="flex flex-row justify-between text-gray-500">
                  <div>
                    <p className="">{item.name}</p>
                  </div>
                  <div className="flex gap-3 mb-1">
                    <p className="">{item.kilograms}</p>
                    <p className="">{item.percent}%</p>
                  </div>
                </div>

                <div className="w-full bg-gray-300 rounded-sm" style={{ height: "5px" }}>
                  <div
                    style={{ width: `${item.percent}%`, height: "5px", backgroundColor: getColorForItem(item.name) }}
                    className="h-full rounded-sm"
                  />
                  </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownRowInfo;
