import React from 'react';
import Paginate from "../paginate";
import { formatNumber } from "../../../services/utilservices";
import { apiFunction, columType } from "./types/tableTypes";
import { TableProps } from "./interfaces/tableInterfaces";
import { ReactComponent as DocumentBlue } from "../../../assets/icons/document-blue.svg";

const Table = <T,>({ columns, data, customRow, pagination, onPageChange, onPageSizeChange }: TableProps<T>) => {

  const handlePageChange = (newPage: number) => {
    onPageChange?.(newPage)
  };

  const handlePageSizeChange = (newSize: number) => {
    onPageSizeChange?.(newSize);
  };

  return (
    <div className="w-full">
      <div className="shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)] p-5 bg-white">
        <div className="flex justify-center">
          <div className="overflow-x-auto w-full p-4">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className={"bg-[#003DA5]/10 divide-x divide-black/10"}>
                    {columns.map((column, index) => (
                      <th scope={'col'}
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 ${index === 0 ? 'sticky left-0 z-10 bg-[#e5ebf6] sticky-header'  : ''} ${column.styleClassCell}`}
                        key={index}
                        style={index === 0 ? { minWidth: '120px', width: '120px' } : {}}
                      >
                        {String(column.name)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data?.map((row: any, rowIndex) => (
                    <tr key={rowIndex} className="divide-x divide-gray-200 even:bg-[#f1f1f1]">
                      {columns.map((column, colIndex) => (
                        <td
                        className={`py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 
                          ${colIndex === 0 ? 'sticky-column  left-0 z-10' : ''} 
                          ${rowIndex % 2 === 0 ? 'bg-[#F1F1F1]' : 'bg-white'}`}
                        key={colIndex}
                        style={colIndex === 0 ? { minWidth: '90px', width: '90px' } : {}}
                        >
                          {renderCell(
                            Array.isArray(column.column_name)
                              ? column.column_name.map((name) => row[name])
                              : row[column.column_name],
                            column.type,
                            column.name,
                            column.api,
                            row
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {customRow && (
                    <tr className="divide-x divide-gray-200 even:bg-[]">
                      {customRow.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          colSpan={cell.colspan || 1}
                          className={'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 text-right'}
                        >
                          {cell.content}
                        </td>
                      ))}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Paginate pageNumber={pagination?.pageNumber} pageSize={pagination?.pageSize} totalCount={pagination?.totalCount} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} />
    </div>
  );
};

const renderCell = (value: any, type: columType, columnName: string, api?: apiFunction, row?: any) => {

  if (value === "Aprobado") {
    return <span className="bg-[#D0FFE3] flex rounded-full justify-center items-center font-medium text-[#219653] w-[94px] h-[30px]">Aprobado</span>;
  }
  if (value === "Rechazado") {
    return <span className="bg-[#FFEEF0] flex rounded-full justify-center items-center font-medium text-[#D34053] w-[94px] h-[30px]">Rechazado</span>;
  }
  if (value === "Objetado") {
    return <span className="bg-[#FFF0D7] flex rounded-full justify-center items-center font-medium text-[#FFA70B] w-[94px] h-[30px]">Objetado</span>;
  }
  if (value === "") {
    return (
      <span className="bg-gray-200 flex rounded-full justify-center items-center font-medium text-gray-600 w-[94px] h-[30px]">
        Pendiente
      </span>
    );
  }


  switch (type) {
    case 'number':
      return formatNumber(value);
    case 'boolean':
      return value ? 'Sí' : 'No';
    case 'option':
      break;
    case 'api':
      if (value !== null && value !== 0) {
        return (
          <div className='flex justify-center items-center h-full'>
            <button
              className="flex items-center cursor-pointer"
              onClick={() => api && api(value, row)}
            >
              <DocumentBlue className="mr-2" /> {columnName}
            </button>
          </div>
        );
      } else {
        return (
          <div className='flex justify-center items-center h-full'>
            <button
              className="flex items-center cursor-not-allowed opacity-50 text-center justify-center items-center"
              disabled
            >
              <DocumentBlue className="mr-2" /> {columnName}
            </button>
          </div>
        );
      }

    //return null;
    case 'string':
    default:
      return value;
  }
};

export default Table;
