import React, { useEffect, useMemo, useState } from 'react';
import Table from "../components/table";
import { CogIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { decryptText, formatNumber } from '../../services/utilservices';
import FilterBy from "../components/FilterBy";
import { FilterByInterface } from "../components/FilterBy/interfaces/filterByInterface";
import { filterDataInterface } from "../components/FilterBy/interfaces/filterByPropsInterface";
import { Column } from "../components/table/interfaces/tableInterfaces";
import { ReceptionDataInterface } from "./interfaces/receptionDataInterface";
import useReceptions from "../../hooks/receptions/useReceptions";
import useExportExcelReceptions from "../../hooks/receptions/useExportExcelReceptions";
import Filtros from "../dashboard/widgets/filtros";
import { useQualityReport } from "../../hooks/receptions/useQualityReport";
import { usePTIReport } from "../../hooks/receptions/usePTIReport";
import { PaginationInterface } from "../components/paginate/interfaces/paginationInterface";
import { toast, ToastContainer } from 'react-toastify';

// Función para formatear la fecha
const formatDate = (dateString: string) => {
  // Asegurarse de cortar los milisegundos adicionales si es necesario
  const validDateString = dateString.slice(0, 19); // Elimina milisegundos adicionales y fracción
  const date = new Date(validDateString); // Crear objeto Date

  // Verificar si la fecha es válida
  if (isNaN(date.getTime())) {
    return "Fecha inválida"; // Devolver un mensaje si la fecha es inválida
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
  const year = date.getFullYear();
  //console.log(`${day}/${month}/${year}`)
  return `${day}-${month}-${year}`;
};

interface Filter {
  filtrarPor: string;
  textoBusqueda: string;
  isLike: boolean;
}
const Reception: React.FC = ({ productorPadreId, productorId, temporadaId, especieId, variedadId }: any) => {
  const { getQualityReport } = useQualityReport();
  // const { getPTIReport } = usePTIReport();

  const handleQualityReport = (numeroPartida: number, row: any) => {
    // if (JSON.parse(sessionStorage.getItem('filters') || '{}').speciesId == 3) {
    //   getPTIReport(numeroPartida);
    // } else {
    const especieId = row.especieId
    const especieNombre=row.especieNombre;
    const variedad=row.variedadNombre;
    const codigo = typeof row.productorNombre === "string" 
    ? row.productorNombre.split("-") 
    : [""];
    getQualityReport(numeroPartida, especieId, especieNombre, variedad, codigo[0]);
    // }
  };

  const columns: Column[] = [
    { column_name: 'productorNombre', name: 'Productor', type: 'string', styleClassCell: '' },
    { column_name: 'variedadNombre', name: 'Variedad', type: 'string', styleClassCell: 'text-center' },
    { column_name: 'guia', name: 'Guía Despacho', type: 'string', styleClassCell: 'text-center' },
    { column_name: 'numeroPartida', name: 'Partida', type: 'string', styleClassCell: 'text-center' },
    { column_name: 'fechaRecepcion', name: 'Fecha Recepción', type: 'string', styleClassCell: 'text-center' },
    { column_name: 'centroNombre', name: 'Nombre Centro', type: 'string', styleClassCell: '' },
    { column_name: 'cantidadEnvases', name: 'Cantidad Envases', type: 'string', styleClassCell: 'text-right' },
    { column_name: 'kilosRecepcionados', name: 'Recepción Kg', type: 'number', styleClassCell: 'text-right' },
    { column_name: 'estado', name: 'Estado', type: 'string', styleClassCell: 'flex justify-center items-center' },
    { column_name: 'numeroPartida', name: 'Inf. de calidad', type: 'api', api: handleQualityReport, styleClassCell: 'text-center justify-center items-center' },
  ];

  const [lista, setData] = useState<ReceptionDataInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [pagination, setPagination] = useState<PaginationInterface | null>(null);
  const [selectedParentProducerId, setSelectedParentProducerId] = useState(productorPadreId);
  const [selectedProducerId, setSelectedProducerId] = useState(productorId);
  const [selectedSeasonId, setSelectedSeasonId] = useState(temporadaId);
  const [selectedSpeciesId, setSelectedSpeciesId] = useState(especieId);
  const [selectedVarietyId, setSelectedVarietyId] = useState(variedadId);
  const [isFiltersSynced, setIsFiltersSynced] = useState(false);

  const [customRow, setCustomRow] = useState([
    { content: 'SUBTOTAL', colspan: 6 }, { content: '0', colspan: 1, }, { content: '0 Kg', colspan: 1, },
  ]);

  const customSelectFilterByOptions = [
    { label: "Filtrar Por:", value: "" },
    { label: "Guía", value: "guia" },
    { label: "Nombre Centro", value: "centroNombre" },
    { label: "Número de Partida", value: "numeroPartida" },
    { label: "Fecha Recepción", value: "fechaRecepcion" },
    
  ];

  useEffect(() => {
    const savedFilters = JSON.parse(decryptText(sessionStorage.getItem('filters') || "") || '{}');

    if (Object.keys(savedFilters).length) {
      setSelectedParentProducerId(savedFilters.parentProducerId || productorPadreId);
      setSelectedProducerId(savedFilters.producerId || productorId);
      setSelectedSeasonId(savedFilters.seasonId || temporadaId);
      setSelectedSpeciesId(savedFilters.speciesId || especieId);
      setSelectedVarietyId(savedFilters.varietyId || variedadId);
    }
  }, []);

  const filters = useMemo(() => ({
    productorId: selectedProducerId,
    productorPadreId: selectedParentProducerId,
    temporadaId: selectedSeasonId,
    especieId: selectedSpeciesId,
    variedadId: selectedVarietyId,
  }), [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const [filterBy, setFilterBy] = useState<FilterByInterface>({
    pageNumber: 1,
    pageSize: 10,
    paginationFilters: [],
  });

  const { receptions, loadingReceptions } = useReceptions(filterBy, isFiltersSynced);
  const { exportToExcel, loadingExport } = useExportExcelReceptions();

  const handleExport = () => {
    exportToExcel(filterBy.paginationFilters)

  };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
    setFilterBy(prevFilterBy => ({
      ...prevFilterBy,
      pageNumber: newPage,
    }));
  };

  const handlePageSizeChange = (newSize: number) => {
    setCurrentPageSize(newSize);
    setFilterBy(prevFilterBy => ({
      ...prevFilterBy,
      pageSize: newSize,
    }));
  };

  useEffect(() => {
    if (receptions?.lista?.length) {
      if (receptions.pagPrevia === null) {
        receptions.pagPrevia = 1;
      }

      const paginationData: PaginationInterface = {
        pageSize: 0,
        pageNumber: 0,
        totalCount: receptions.total
      }

      //const items:any = receptions.data.items
      const lista: any = receptions.lista.map((lista: any) => ({
        ...lista,
        fechaRecepcion: formatDate(lista.fechaRecepcion) // Formatear la fecha aquí
      }));
      /*const subtotal = items.reduce((sum: number, item: any) => {
        return sum + (parseFloat(item.kilosRecepcionados) || 0);
      }, 0);*/

      setData(lista);
      setPagination(paginationData);
      const subtotal = receptions.lista[0].totalKilosRecepcionados;
      const totalEnvases = receptions.lista[0].totalEnvases;

      setCustomRow(prevCustomRow => [
        prevCustomRow[0],
        {
          ...prevCustomRow[2],
          content: `${formatNumber(totalEnvases)} `,
        },
        {
          ...prevCustomRow[1],
          content: `${formatNumber(subtotal)} Kg`,
        },
      ]);

    }
    else {
      setPagination({
        pageSize: 0,
        pageNumber: 0,
        totalCount: 0,
      });

      setCustomRow(prevCustomRow => [
        prevCustomRow[0],
        {
          ...prevCustomRow[2],
          content: `${formatNumber(0)} `,
        },
        {
          ...prevCustomRow[1],
          content: `${formatNumber(0)} Kg`,
        },
      ]);

      // También puedes limpiar la data si es necesario
      setData([]);
      //setCustomRow([]);
    }
  }, [receptions?.lista]);

  useEffect(() => {
    const savedFilters = JSON.parse(decryptText(sessionStorage.getItem('filters') || "") || '{}');

    if (filters.especieId == savedFilters.speciesId && filters.productorId == savedFilters.producerId && filters.productorPadreId == savedFilters.parentProducerId && filters.temporadaId == savedFilters.seasonId && filters.variedadId == savedFilters.varietyId) {
      const newPaginationFilters = Object.keys(filters).reduce((acc, key) => {
        const filterValue = filters[key as keyof typeof filters];
        if (filterValue) {
          acc.push({
            filtrarPor: key,
            textoBusqueda: `${filterValue}`,
            isLike: false,
          });
        }
        return acc;
      }, [] as any[]);

      setCurrentPage(1);
      setFilterBy(prevFilterBy => ({
        ...prevFilterBy,
        paginationFilters: newPaginationFilters,
        pageNumber: 1,
      }));

      setIsFiltersSynced(true);
    } else {
      setIsFiltersSynced(false);
    }
  }, [filters]);

  const handleFilterChange = (filterData: filterDataInterface) => {
    setFilterBy(prevFilterBy => {
      const existingFilters: Filter[] = prevFilterBy.paginationFilters || [];
      let updatedFilters: Filter[];

      // Si no hay búsqueda ni filtro seleccionado, eliminamos el último filtro
      if (filterData.selectedFilter === '' && filterData.searchText === '') {
        updatedFilters = existingFilters.slice(0, existingFilters.length - 1); // Elimina el último filtro
      } else {
        // Si el campo de búsqueda está vacío pero se ha seleccionado un filtro, desactivamos la búsqueda "like"
        if (filterData.searchText === '') {
          updatedFilters = existingFilters.map((filter: Filter) => {
            if (filter.filtrarPor === filterData.selectedFilter) {
              return {
                ...filter,
                textoBusqueda: '',  // Limpiamos el textoBusqueda
                isLike: false,  // Desactivamos la búsqueda "like"
              };
            }
            return filter;
          });

          // Si no existe un filtro con el filtro seleccionado, lo agregamos
          const filterExists = updatedFilters.some((filter: Filter) => filter.filtrarPor === filterData.selectedFilter);

          if (!filterExists) {
            updatedFilters.push({
              filtrarPor: filterData.selectedFilter,
              textoBusqueda: '',  // Limpiamos el textoBusqueda
              isLike: false,  // Desactivamos la búsqueda "like"
            });
          }
        } else {
          // Si el campo de búsqueda tiene texto, lo aplicamos al filtro
          updatedFilters = existingFilters.map((filter: Filter) => {
            if (filter.filtrarPor === filterData.selectedFilter) {
              return {
                ...filter,
                textoBusqueda: filterData.searchText,
                isLike: true,  // Activamos la búsqueda "like"
              };
            }
            return filter;
          });

          // Si no existe un filtro con el filtro seleccionado, lo agregamos con el valor de búsqueda
          const filterExists = updatedFilters.some((filter: Filter) => filter.filtrarPor === filterData.selectedFilter);

          if (!filterExists) {
            updatedFilters.push({
              filtrarPor: filterData.selectedFilter,
              textoBusqueda: filterData.searchText,
              isLike: true,
            });
          }
        }
      }

      // Devolvemos el estado actualizado con los filtros modificados
      return {
        ...prevFilterBy,
        paginationFilters: updatedFilters,
      };
    });

    // Si no hay texto de búsqueda y no se seleccionó filtro, solo reseteamos la página
    setCurrentPage(1);
  };
  let prod = decryptText(sessionStorage.getItem('prod') || "");
  let parsedProd = prod ? JSON.parse(prod) : [];
  const perfil = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).perfilDescripcion || null;
  return (
    <div className="w-full">
      <ToastContainer />
      {loadingReceptions && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
          <div className="text-white flex items-center space-x-3">
            <CogIcon className="w-8 h-8 animate-spin" /> {/* Ícono de coche girando */}
            <span className="text-xl">Procesando...</span>
          </div>
        </div>
      )}
      <div className={`py-[23px] px-[35px] min-h-[181px] w-full bg-[linear-gradient(90deg,_#01AAEF_0.72%,_#2C2D65_100%)]`}>
        <div className={'mb-[22px]'}>
          <h1 className={'font-bold text-[24px] page-title text-[#FFFFFF]'}>Recepción</h1>
          <p className={'text-[12px] pt-[8px] page-subtitle text-[#FFFFFF] flex'}>Recepciones por lote (Kg) y Resultado de Inspección de calidad.<InformationCircleIcon className="size-5 text-[#FFFFFF]" /></p>
        </div>
        <div className={'flex flex-wrap items-center mb-[20px] min-h-[40px]'}>
          <Filtros
            column={`nombrePadre`}
            onProducerChange={setSelectedProducerId}
            onParentProducerChange={setSelectedParentProducerId}
            onSeasonChange={setSelectedSeasonId}
            onSpeciesChange={setSelectedSpeciesId}
            onVarietyChange={setSelectedVarietyId}
          />
        </div>
      </div>
      <hr />
      <div className={`p-6`}>
        <p className="text-xs text-gray-500 mb-2">
          Paso 1: Escoge un filtro. Paso 2: Ingresa el texto a buscar".
        </p>
        <div className="flex w-full items-center justify-center mb-[15px] mt-[12px]">
          <FilterBy childrenOptions={customSelectFilterByOptions} onFilterChange={handleFilterChange} />
          {(parsedProd.length > 0 || perfil !== "Productor") && ( 
          <button
            onClick={handleExport}
            className="flex justify-center items-center w-[300px] h-[42px] bg-[#00C7B1] rounded-[6px] text-white ml-3 "
            disabled={loadingExport}
          >
            {loadingExport ? (
              <>
                <span className="loader"></span>
                <span className="ml-2">Cargando...</span>
              </>
            ) : (
              <>
                Consolidado de recepciones
                <DownloadIcon className={`ml-3.5`} />
              </>
            )}
          </button>
          )}
        </div>
        <Table columns={columns} data={lista} customRow={customRow} pagination={pagination} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} />
      </div>
    </div>
  );
}

export default Reception;