import React, { useState, useEffect ,useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { callApi, decryptText } from '../../../services/utilservices';
import CryptoJS  from 'crypto-js';
import axios from 'axios';
import Select from 'react-select'; 
import { useSnackbar } from '../../../context/SnackbarContext';
import { CogIcon  } from "@heroicons/react/20/solid";
interface Producer {
  id: number;
  nombre: string;
}

const CreateUser = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [rut, setRut] = useState('');
  const [profileType, setProfileType] = useState('');
  const [producerList, setProducerList] = useState<Producer[]>([]);
  const [selectedProducer, setSelectedProducer] = useState<Producer[]>([]);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const itemsPerPage = 10;
  const [data, setData] = useState<Producer[]>([]);
  const [rutError, setRutError] = useState('');
  const { showMessage } = useSnackbar(); // mensaje 
  const rutInputRef = useRef<HTMLInputElement>(null)
  // Nuevos estados para las contraseñas
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isValid, setIsValid] = useState(false);
  const season = decryptText(sessionStorage.getItem('season') || "");
  let idseason=5;
  if(season=="2024-2025"){
   idseason=5;
  }
  else{
    idseason=1;
  }
  useEffect(() => {
    setIsValid(true);
    const fetchProducers = async () => {
      try {
        const result = await callApi({
          url: `Productor/1/3000/null/null/${idseason}`,
          method: 'GET',
        });
  
        const producerList = result.lista || [];
        setTotalCount(result.total || 0);
  
        if (Array.isArray(producerList)) {
          // Filtrar los productores donde esPadre sea true
          const filteredProducers = producerList.filter(producer => producer.esPadre === true);
  
          // Actualizar los estados con los datos filtrados
          setData(filteredProducers);
          setProducerList(filteredProducers);
          
        } else {
          console.error("Los datos recibidos no son un array:", producerList);
        }
      } catch (error) {
        console.error('Error al obtener la lista de productores:', error);
      }
    };
  
    fetchProducers();
  }, []);
  
  
  
  const hashPassword = (password:any) => {
    return CryptoJS.SHA256(password).toString(); // Hash SHA256
  };
  
  const validateRUT = (rut: any) => {
    const cleanRUT = rut.replace(/[^0-9kK]/g, '');

    
    

    // Bloquear RUTs con caracteres repetidos como 11.111.111-1
    const body = cleanRUT.slice(0, -1);
    if (/^(\d)\1+$/.test(body)) return false; // Verifica si todos los caracteres son iguales
    if (!/^\d+$/.test(body) || /^[1-9]$/.test(body)) return false;
    if (cleanRUT.length < 2) return false;

    const verifier = cleanRUT.slice(-1).toUpperCase();
    if (!/^\d+$/.test(body)) return false;

    let sum = 0;
    let multiplier = 2;

    for (let i = body.length - 1; i >= 0; i--) {
        sum += parseInt(body.charAt(i), 10) * multiplier;
        multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const remainder = sum % 11;
    const calculatedVerifier = remainder === 0 ? '0' : remainder === 1 ? 'K' : (11 - remainder).toString();

    return calculatedVerifier === verifier;
};

const formatRUT = (rut:any) => {
    const cleanRUT = rut.replace(/[^0-9kK]/g, '');
    if (!cleanRUT) return '';

    const body = cleanRUT.slice(0, -1);
    const verifier = cleanRUT.slice(-1).toUpperCase(); // Asegúrate de que el verificador esté en mayúsculas

    // Formato del cuerpo con puntos
    const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${formattedBody}-${verifier}`;
};

const handleRUTChange = (e:any) => {
    const value = e.target.value.toUpperCase(); // Convertir el valor a mayúsculas
    const formattedRUT = formatRUT(value);
    setRut(formattedRUT);

    // Validar el RUT
    if (validateRUT(formattedRUT)) {
        setRutError('');
       
    } else {
        setRutError('RUT no válido');
        
    }
};
const handleRUTBlur = () => {
  // Validar al perder el foco
  if (!validateRUT(rut)) {
      setRutError('RUT no válido');
      rutInputRef.current?.focus(); // Forzar el foco de nuevo en el input
  }
};

const validatePassword = (password: string) => {
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*.]/.test(password);
  const isLongEnough = password.length >= 12;

  if (!hasUpperCase) {
    return 'La contraseña debe contener al menos una letra mayúscula.';
  }
  if (!hasSpecialChar) {
    return 'La contraseña debe contener al menos un carácter especial.';
  }
  if (!isLongEnough) {
    return 'La contraseña debe tener al menos 12 caracteres.';
  }
  
  
};

const options = producerList.map(producer => ({
  value: producer.id,
  label: producer.nombre,
}));
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
   // console.log(name,email,rut,phone, selectedProducer,profileType)
    // Verifica si todos los campos requeridos están completos
    if (!name || !email || !password) {
      showMessage('Completa todos los campos', 'error'); 
      setLoading(false);
      return;
    }

   
    
      // Validar contraseñas
      const passwordValidationResult = validatePassword(password);
  
      if (typeof passwordValidationResult === 'string') {
        showMessage(passwordValidationResult, 'error'); 
        //setPasswordError(passwordValidationResult);
        setLoading(false);
        return;
      }
      if(password != confirmPassword)
      {
        showMessage('Las contraseñas no coinciden', 'error'); 
        //setPasswordError('Las contraseñas no coinciden');
        setLoading(false);
        return;
      }
    
      // Si no hay errores, puedes limpiar el mensaje de error o proceder
      setPasswordError(''); 
    // Mapear los perfiles a valores numéricos según lo esperado
    const profileMap: { [key: string]: number } = {
      'Administrador': 1,
      'Productor': 3,
      'Estandar': 2,
    };
   /* if (selectedProducer.length == 0 && profileMap[profileType] == 3) {
      showMessage('Debe seleccionar un productor', 'error');
      setLoading(false);
      return;
    }*/ 
  
    if(profileMap[profileType]==undefined){
      showMessage('Debe seleccionar un perfil', 'error');
      setLoading(false);
      return;
    }
    const userPayload = {
      
        nombre: name,
        correo: email,
        telefono: phone,
        rut: rut,
        perfil: profileMap[profileType],
        contrasena: hashPassword(password), 
        confirmaContrasena: hashPassword(confirmPassword), 
        productores: selectedProducer.map((producer) => producer.id), 
      
    };

    try {
      setLoading(true);
      const response = await callApi({
        url: 'Usuarios',
        method: 'POST',
        data: userPayload,
      });
     if(response){
      showMessage('Usuario creado exitosamente', 'success'); 
      setTimeout(() => {
        navigate('/administracion/usuarios');
      }, 1000);
     }
     
    } catch (error) {
        if (axios.isAxiosError(error)) {

          if (error.response) {
           // console.log(error.response)
              const errorResponse = error.response.data; 
              showMessage(errorResponse || "Ocurrió un error inesperado.", 'error'); 
             
             
          } else {
              // Error de red o configuración
              console.error('Error de red o configuración:', error);
              showMessage("Ocurrió un error de red. Por favor, intenta de nuevo.", 'error'); 
              
          }
      } else {

          showMessage("Ocurrió un error de red. Por favor, intenta de nuevo.", 'error'); 
         
      }
    }
    finally {
     
      setLoading(false); // Desactivamos el spinner una vez la petición esté completa
     
    }
  };

  const handleClose = () => {
    navigate(`/administracion/usuarios/`);
  };
 

  const handlePhoneChange = (e: any) => {
    const input = e.target.value;

    // Validar que no sea una secuencia repetida (ej. 111111111, 222222222)
    if (/^(\d)\1{8}$/.test(input)) {
      showMessage('El número de teléfono no puede ser una secuencia repetida','error');
      setIsValid(false);
      //console.log(isValid)
      return;
    }

    // Permitir solo números y limitar a 9 caracteres
    if (/^\d{0,9}$/.test(input)) {
      setPhone(input);
      setPhoneError(''); 
      setIsValid(true);
    }
  };

  return (
    <div className="w-full p-6">
      <div className="max-w-[1009px] min-h-[553px] h-full w-full px-[36px] pt-[26px] pb-[40px] shadow rounded-lg">
        <h2 className="text-2xl font-semibold text-[#111928] page-title">Nuevo Usuario</h2>
        <p className="text-gray-500 mt-2 page-subtitle">
         Gestiona la creación de usuarios.
        </p>
        {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
          <div className="text-white flex items-center space-x-3">
            <CogIcon  className="w-8 h-8 animate-spin" /> {/* Ícono de coche girando */}
            <span className="text-xl">Procesando...</span>
          </div>
        </div>
      )}
        <div className="mt-[42px]">
          <form onSubmit={handleSubmit} className="">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Nombre Completo</label>
                <input
                  type="text"
                  placeholder="Ingresa el nombre y apellido del usuario"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">E-mail</label>
                <input
                  type="email"
                  placeholder="Ingresa la dirección de correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Número celular</label>
                <div className="flex">
                  <select className="h-[42px] px-3 py-2 border rounded-l">
                    <option value="+56">+56</option>
                  </select>
                  <input
                    type="text"
                    placeholder="965893546"
                    value={phone}
                    onChange={handlePhoneChange}
                    className={`outline-0 w-full h-[42px] px-3 py-[9px] border rounded-[6px] ${!isValid ? 'border-red-500' : ''}`}
                  />
                </div>
                {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>} {/* Mostrar mensaje de error */}
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">RUT</label>
                <input
                  type="text"
                  placeholder="Ingresa el RUT del usuario"
                  value={rut}
                  ref={rutInputRef}
                  maxLength={12}
                  onBlur={handleRUTBlur}
                  onChange={handleRUTChange}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
                 {rutError && <p className="text-red-500 text-sm">{rutError}</p>}
              </div>

              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Contraseña</label>
                <input
                  type="password"
                  placeholder="Ingresa una contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Confirmar Contraseña</label>
                <input
                  type="password"
                  placeholder="Confirma tu contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
                {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Tipo de Perfil</label>
                <select
                  value={profileType}
                  onChange={(e) => setProfileType(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                >
                  <option value="">Seleccione un Perfil</option>
                  <option value="Administrador">Administrador</option>
                  <option value="Productor">Productor</option>
                  <option value="Estandar">Estandar</option>
                </select>
              </div>
              {profileType === "Productor" && (
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Listado de Productores</label>
                <Select
                  isMulti
                  id="producer"
                  options={options}
                  onChange={(newValues) => {
                    // newValues es un array de objetos o puede ser nulo
                    const selectedProducers = newValues 
                      ? newValues.map((item) => ({
                          id: item.value,
                          nombre: item.label,
                        })) 
                      : []; // Si newValues es nulo, se asigna un array vacío

                    setSelectedProducer(selectedProducers); // Asegúrate de que este es un array
                  }}
                  isClearable
                  placeholder="Selecciona un productor"
                />
 
              </div>
              )}
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={handleClose}
                className="px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded hover:bg-blue-50 w-[196px] h-[42px]"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 w-[196px] h-[42px]"
                disabled={!isValid} 
              >
                Crear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
