import { Navigate } from 'react-router-dom';
import React from 'react';
import { decryptText } from '../services/utilservices';

interface AdminPrivateRouteProps {
  children: React.ReactNode;
}

function AdminPrivateRoute({ children }: AdminPrivateRouteProps) {
  const user = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')) || null;

  if (!user.token) {
    sessionStorage.clear();
    return <Navigate to="/login" replace />;
  }

  if (user.perfilDescripcion != "Administrador")
    return <Navigate to="/dashboard" replace />;
  
  return <>{children}</>;
}

export default AdminPrivateRoute;
