import React, { useState } from "react";
import { decryptText, formatNumber } from "../../../../services/utilservices";
import { Tooltip } from "react-tooltip";
import { MagnifyingGlassIcon   } from "@heroicons/react/20/solid";

export interface CurvaProduccionData {
  data: {
    temporada: string;
    tamano: string;
    kilosAsociados: number;
    total: number;
    kg: number;
    porcent: number;
    ponderado: number;
  }[];
  productorNombre: string;
}

const CurvaProduccion: React.FC<CurvaProduccionData> = ({
  data = [],
  productorNombre = "",
}) => {
  const currentDate = new Date().toLocaleDateString("es-ES");
  const perfil = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).perfilDescripcion || null;
  const species = decryptText(sessionStorage.getItem("species") || "");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="shadow-md border rounded-lg mt-2 px-6 py-6 min-h-[500px] border-b default-font w-full">
        <div className="flex justify-between items-center">
          <p className="text-2xl font-bold">Curva de Calibre de Producción</p>
          <button
            className="relative flex items-center justify-center text-gray-600 hover:text-gray-800"
            onClick={toggleModal}
          >
            <MagnifyingGlassIcon className="w-6 h-6 mr-2" />
            <span className="absolute top-1/3 transform -translate-x-1/2 -translate-y-1/2 text-xl font-bold">+</span>
          </button>
        </div>
        <p className="text-md mb-6">Última actualización {currentDate}</p>
        <div className="flex flex-row items-center mb-4">
          <div className="border rounded-[10px] bg-percent1-segregacion h-[10px] w-[10px] mr-1"></div>
          <p className="mr-6">
            {decryptText(sessionStorage.getItem("producer") || '') ||
              decryptText(sessionStorage.getItem("parentProducer") || '') ||
              "Todas"}
          </p>
          <div className="border rounded-[10px] bg-percent2-segregacion h-[10px] w-[10px] mr-1"></div>
          <p>DDC</p>
        </div>
        <div className="px-6 overflow-y-auto h-[300px]">
          {data.map((item, index) => (
            <div key={index} className="flex flex-col">
              <p className="mb-2">{item.tamano}</p>
              <div
                data-tooltip-id="porcentaje"
                data-tooltip-content={
                  !(perfil === "Productor" && species === "ARANDANOS")
                    ? "Productor: " + formatNumber(item.kg) + " Kg"
                    : ""
                }
                className="flex flex-row justify-between items-center gap-3"
              >
                <hr
                  style={{ width: `${item.porcent}%`, height: "15px" }}
                  className="bg-percent1-segregacion rounded-sm"
                />
                <p>{item.porcent.toFixed(1)}%</p>
              </div>
              <Tooltip id="porcentaje" />
              <hr className="my-1 border-t-2 border-dotted border-gray-400" />
              <div
                data-tooltip-id="ponderado"
                data-tooltip-content={
                  perfil === "Administrador" || perfil === "Estandar"
                    ? "DDC: " + formatNumber(item.total) + " Kg"
                    : ""
                }
                className="flex flex-row justify-between items-center gap-3"
              >
                <hr
                  style={{ width: `${item.ponderado}%`, height: "15px" }}
                  className="bg-percent2-segregacion rounded-sm"
                />
                <p>{item.ponderado.toFixed(1)}%</p>
              </div>
              <Tooltip id="ponderado" />
              <hr className="my-1 border-t-2 border-dotted border-gray-400" />
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 font-inherit">
          <div className="bg-white rounded-lg shadow-lg w-[90%] max-w-[800px] p-6 font-titillium">
            <div className="flex justify-between items-center mb-4">
              <p className="text-xl font-bold">Curva de Calibre de Producción</p>
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={toggleModal}
              >
                ✕
              </button>
            </div>
            <div className="overflow-y-auto h-[500px] px-6">
              {data.map((item, index) => (
                <div key={index} className="flex flex-col">
                  <p className="mb-2">{item.tamano}</p>
                  <div
                    data-tooltip-id="porcentaje"
                    data-tooltip-content={
                      "Productor: " + formatNumber(item.kg) + " Kg"
                    }
                    className="flex flex-row justify-between items-center gap-3"
                  >
                    <hr
                      style={{ width: `${item.porcent}%`, height: "15px" }}
                      className="bg-percent1-segregacion rounded-sm"
                    />
                    <p>{item.porcent.toFixed(1)}%</p>
                  </div>
                  <Tooltip id="porcentaje" />
                  <hr className="my-1 border-t-2 border-dotted border-gray-400" />
                  <div
                    data-tooltip-id="ponderado"
                    data-tooltip-content={
                      perfil === "Administrador" || perfil === "Estandar"
                        ? "DDC: " + formatNumber(item.total) + " Kg"
                        : ""
                    }
                    className="flex flex-row justify-between items-center gap-3"
                  >
                    <hr
                      style={{ width: `${item.ponderado}%`, height: "15px" }}
                      className="bg-percent2-segregacion rounded-sm"
                    />
                    <p>{item.ponderado.toFixed(1)}%</p>
                  </div>
                  <Tooltip id="ponderado" />
                  <hr className="my-1 border-t-2 border-dotted border-gray-400" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CurvaProduccion;


