import {callApi, handleDownloadBase64} from "../utilservices";
import {ExportExcelReceptionsInterface} from "./interfaces/exportExcelReceptionsInterface";

export const fetchExportReceptions= async (filters:ExportExcelReceptionsInterface[]) => {
  let url= `Recepciones/Consolidado`;
  try {
    const response = await callApi({
      url: url,
      method: 'POST',
      data: {
        paginationFilters: filters,
      },
      responseType: 'blob' as 'json', 
    });

    if (response instanceof Blob) {
      return response; 
    } else {
      console.error('No se pudo descargar el archivo.');
    }
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  }
};
