import { Navigate } from 'react-router-dom';
import React from 'react';
import { decryptText } from '../services/utilservices';

interface PrivateRouteProps {
  children: React.ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const isAuthenticated = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).token || null;

  // Si el usuario no está autenticado, redirigir a login
  if (!isAuthenticated) {
    sessionStorage.clear();  // Limpia sessionStorage si no hay token
    return <Navigate to="/login" replace />;
  }

  // Si el usuario está autenticado, permitir acceso a la ruta privada
  return <>{children}</>;
}

export default PrivateRoute;
