import React from "react";
import DropdownRowInfo from "../../../components/dropdownRowInfo";
import DoughnutChart from "../doughnutChart";
import { ChartData } from "chart.js";
import { ReactComponent as Car } from "../../../../assets/icons/car.svg";
import { ReactComponent as Warning } from "../../../../assets/icons/warning.svg";
import { ReactComponent as Exportation } from "../../../../assets/icons/exportation.svg";
import { ReactComponent as Processes } from "../../../../assets/icons/processes.svg";
import { ReactComponent as Commercial } from "../../../../assets/icons/commercial.svg";
import { formatNumber } from "../../../../services/utilservices";

interface RowInfoProps  {
  rowData: any;
}

const RowInfo: React.FC<RowInfoProps> = ({ rowData }) => {
  if (!rowData || !rowData.listaCajaBulto || rowData.listaCajaBulto.length === 0) {
    return <p></p>;
  }
  const listaCajaBulto = rowData.listaCajaBulto[0]; 
  const cajasBase = listaCajaBulto.cajasBase || 0; 
  const totalContratado = listaCajaBulto.totalContratado || 0;
  const restante = totalContratado - cajasBase; 

  const currentDate = new Date().toLocaleDateString('es-ES');

  let totalKilosComerciales = 0;
  let totalPorcentajeComercial = 0;
  let totalKilosExportables = 0;
  let totalPorcentajeExportacion = 0;
  let totalKilosProcesados = 0;
  let totalPorcentajeProcesados = 0;
  let totalKilosRecepcionados = 0;

  if (rowData) {
    totalKilosComerciales = rowData.totalComercialKG;
    totalPorcentajeComercial = rowData.totalComercialPor;
    totalKilosExportables = rowData.totalExportacionKG;
    totalPorcentajeExportacion = rowData.totalExportacionPor;
    totalKilosProcesados = rowData.totalProcesadosKG;
    totalPorcentajeProcesados = rowData.totalProcesadosPor;
    totalKilosRecepcionados = rowData.totalRecepcionesKG;
  }

  const chartData: ChartData<'doughnut'> = {
    labels: [''],
    datasets: [
      {
        data: [cajasBase, restante], 
        backgroundColor: [
          'rgba(1, 170, 239, 1)',
          'rgba(189, 205, 232, 1)',
        ],
        borderColor: [
          'rgba(1, 170, 239, 1)',
          'rgba(189, 205, 232, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

 /* const dropdownInfoProcesados = [
    { name: "Exportados", kilograms: 11.600, percent: 100 },
    { name: "Calibrado", kilograms: 7.915, percent: 23.70 },
    { name: "Comercial", kilograms: 0.00, percent: 20.05 },
    { name: "Pre-calibre", kilograms: 0.00, percent: 100 },
    { name: "Sobre-calibre", kilograms: 11.600, percent: 100 },
    { name: "Industrial", kilograms: 7.915, percent: 40 },
    { name: "Desecho", kilograms: 0.00, percent: 20.05 },
    { name: "Merma", kilograms: 0.00, percent: 20 },
  ]*/
    let dropdownInfoProcesados = [];

    if (rowData && rowData.listaProcesadosDetalle) {
      dropdownInfoProcesados = rowData.listaProcesadosDetalle.map((item:any) => ({
        name: item.nombre || "Sin nombre",  // Fallback en caso de datos incompletos
        kilograms: formatNumber(item.kg) + " Kg"|| 0.00,
        percent: Number(item.porc || 0).toFixed(1), 
      }));
    }
  /*const dropdownInfoComercial = [
    { name: "Comercial", kilograms: 0.00, percent: 20.05 },
    { name: "Pre-calibre", kilograms: 0.00, percent: 100 },
    { name: "Sobre-calibre", kilograms: 11.600, percent: 100 },
    { name: "Industrial", kilograms: 7.915, percent: 40 },
  ]*/
    let dropdownInfoComercial = [];

    if (rowData && rowData.listaProcesadosDetalleComercial) {
      dropdownInfoComercial = rowData.listaProcesadosDetalleComercial.map((item: any) => ({
        name: item.nombre || "Sin nombre", // Fallback en caso de datos incompletos
        kilograms: formatNumber(item.kg) + " Kg"|| 0.00,
        percent: Number(item.porc || 0).toFixed(1), 
      }));
    }
    
  return (
    <div className="border-b border-border-gray py-4 px-3 default-font flex flex-wrap">
      <div className="balance-container flex gap-3 flex-wrap">
        <div className="flex flex-col w-full flex-wrap">
          <div className="mb-5">
            <p className="text-2xl font-bold">Balance de masa</p>
            <p className="text-md">Última actualización {currentDate}</p>
          </div>
          <div className="container-mass-balance flex flex-row gap-3 justify-center flex-wrap">
            <div className="mass-balance w-full max-w-[400px] flex flex-col bg-[#DADADA] border rounded-lg justify-between py-2.5 px-2">
              <div className="flex flex-row justify-between py-6 px-3.5 h-[123px]">
                <div className="flex flex-row gap-3 items-center">
                  <div className={`icon-chart-blue h-[45px] w-[45px]`}>
                    <Car className={`text-white`} />
                  </div>
                  <p className="font-semibold">Recepciones</p>
                </div>
                <div className={`flex items-center`}>
                  <p className="text-xl font-normal">
                    {formatNumber(totalKilosRecepcionados)}
                    <span className="text-blue-500 text-xs font-bold ml-2">Kg</span>
                  </p>
                  {/*<p className="text-lg font-normal text-tran">%</p>*/}
                </div>
              </div>
              
              {/* {rowData?.listaRecepcionesRechazado?.map((item: any, index: any) => (
                <div
                  key={index}
                  className="balance-card flex flex-row flex-wrap items-center justify-between gap-3 shadow-lg rounded-lg bg-white border px-3.5 py-6 mt-1"
                >
                  <div className="flex flex-row gap-3 items-start">
                    <div className="icon-chart-red h-[45px] w-[45px]">
                      <Warning className="text-white" />
                    </div>
                    <p className="font-semibold text-[14px] text-[#FC1616]">
                      {item.elemento || "Sin nombre"}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-xl font-normal">
                      {item.kg?.toLocaleString('es-ES', { minimumFractionDigits: 2 }) || "000.000,00"}{" "}
                      <span className="text-red-500 text-sm">Kg</span>
                    </p>
                    <p className="text-lg font-normal">
                      {item.porc?.toLocaleString('es-ES', { minimumFractionDigits: 2 }) || "000,00"}{" "}
                      <span className="text-red-500 text-sm">%</span>
                    </p>
                  </div>
                </div>
              ))} */}

              
            </div>
            <div className="mass-balance text-black w-full flex flex-col flex-wrap bg-[#DADADA] border rounded-lg">
              <div className={`flex flex-row gap-4 py-2.5 px-1.5 flex-wrap`}>
                <div className="balance-card w-full flex flex-row gap-6 py-6 px-3.5 justify-start">
                  <DropdownRowInfo
                    SVGIcon={Processes}
                    description="Procesados"
                    childrenOptions={dropdownInfoProcesados}
                  />
                  <div className="flex flex-col">
                    <p className="flex align-bottom text-xl font-normal">
                      {formatNumber(totalKilosProcesados)} <span className="text-blue-500 text-sm flex items-center mt-1 ml-2"> Kg</span>
                    </p>
                    <p className="text-lg font-normal">
                      {Number(totalPorcentajeProcesados).toFixed(0)} <span className="text-blue-500 text-sm"> %</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="card-commercial-exportation flex flex-row gap-4 py-2.5 px-1.5 flex-wrap">
              <div className="balance-card w-full border rounded-lg bg-white flex flex-row gap-6 py-6 px-3.5 justify-between">
                  <div className="flex flex-row gap-2 items-center">
                    <div className={`icon-chart-blue h-[45px] w-[45px]`}>
                      <Exportation className={`text-white`} />
                    </div>
                    <p className="font-semibold text-[14px]">Exportación</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-xl font-normal">
                      {formatNumber(totalKilosExportables)}
                      <span className="text-blue-500 text-sm ml-2">Kg</span>
                    </p>
                    <p className="text-lg font-normal">
                      {Number(totalPorcentajeExportacion).toFixed(0)} <span className="text-blue-500 text-sm">%</span>
                    </p>
                  </div>
                </div>
                
                <div className="balance-card w-full border rounded-lg bg-white flex flex-row gap-6 py-6 px-3.5 justify-between">
                  <div className="">
                    <DropdownRowInfo
                      SVGIcon={Commercial}
                      description="Comercial"
                      childrenOptions={dropdownInfoComercial}
                    />
                    <br />
                    {/* <small>(Comercial + PreCalibre)</small>
                    <br></br>
                    <small>*Estos valores presentados, no incluyen merma ni desecho.</small> */}
                  </div>
                  
                  <div className="flex flex-col">
                    <p className="text-xl font-normal">
                      {formatNumber(totalKilosComerciales)} <span className="text-blue-500 text-sm ml-1">Kg</span>
                    </p>
                    <p className="text-lg font-normal">
                      {Number(totalPorcentajeComercial).toFixed(0)} <span className="text-blue-500 text-sm">%</span>
                    </p>
                  </div>

                </div>                
              </div>
            </div>
            <div className="mass-balance w-full max-w-[400px] flex flex-col bg-[#DADADA] border rounded-lg justify-center items-center py-6 px-4 min-h-[250px]">
              <div className="flex flex-col justify-center items-center w-full h-full">
                <p className="text-2xl font-bold mb-4"></p>
                <div className="flex justify-center items-center w-full">
                  <div className="w-[230px] h-[230px]">
                    <DoughnutChart chartData={chartData} />
                  </div>
                </div>
                <div className="mt-4">
                  {/* <p className="text-lg">
                    <span className="adasd">Total Contratado:</span> {formatNumber(totalContratado)} Kg
                  </p> */}
                  {/* <p className="adasd">
                    <span className="none">Cajas Base:</span> {formatNumber(cajasBase)} Kg
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowInfo;

{
  /* <Widget
        classNameContainer="rounded-md bg-white my-6 shadow-md"
        classNameContent="flex flex-row justify-between p-5"
      >
        {infoArray.map((info) => (
          <div className="w-full max-w-48 text-ddc-blue flex flex-col border-r border-border-gray last:border-r-0">
            <span className="font-semibold flex flex-row">
              {info.title}
              {/* <img src={info.icon} alt='icon' /> */
}
//         </span>
//         <span className="flex flex-row gap-1 items-end">
//           <p className="text-3xl">{info.kilos}</p> Kg
//         </span>
//         {info.porcentaje && (
//           <span className="flex flex-row gap-1 items-end">
//             <p className="text-2xl">{info.porcentaje}</p>{" "}
//             <p className="text-green-300">%</p>
//           </span>
//         )}
//       </div>
//     ))}
//   </Widget>
