import Navtab from "../components/navtab";
import BarChart from "./widgets/barChart";
import CurvaProduccion from "./widgets/curvaProduccion";
import Filtros from "./widgets/filtros";
import RowInfo from "./widgets/rowInfo";
import SegregacionProduccion from "./widgets/segregacionProduccion";
import useDashboard from "../../hooks/dashboard/useDashboard";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import TermsConditions from "../components/TermsConditions";
import { decryptText, encryptText } from "../../services/utilservices";
import { updateTermsUser } from "../../services/users/users";
import CogIcon from "@heroicons/react/20/solid/CogIcon";


const Dashboard = ({ productorPadreId, productorId, temporadaId, especieId, variedadId }: any) => {
  const [selectedParentProducerId, setSelectedParentProducerId] = useState(productorPadreId);
  const [selectedProducerId, setSelectedProducerId] = useState(productorId);
  const [selectedSeasonId, setSelectedSeasonId] = useState(temporadaId);
  const [selectedSpeciesId, setSelectedSpeciesId] = useState(especieId);
  const [selectedVarietyId, setSelectedVarietyId] = useState(variedadId);
  const [isFiltersSynced, setIsFiltersSynced] = useState(false);
  const [showTerms, setShowTerms] = useState(false); 
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false); 
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showWarning || loading) {
      document.body.style.overflow = "hidden"; 
    } else {
      document.body.style.overflow = "unset"; 
    }

    return () => {
      document.body.style.overflow = "unset"; 
    };
  }, [showWarning, loading]);

  useEffect(() => {
    const termsAccepted: boolean = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}')).aceptaTerminosyCondiciones;
    
    if (termsAccepted) {
      setHasAcceptedTerms(true); 
      setShowTerms(false); 
    } else {
      setHasAcceptedTerms(false); 
      setShowTerms(true); 
    }
  }, []);

  const handleReject = () => {
    setShowTerms(false); 
    setShowWarning(true);
    setLoading(true); 

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };


  const handleAccept = () => {
    setShowTerms(false); 
    setShowWarning(false);
    let data = JSON.parse(decryptText(sessionStorage.getItem('data') || '{}'));
    data.aceptaTerminosyCondiciones = true;
    sessionStorage.setItem('data', encryptText(JSON.stringify(data)));
  };

  const handleWarningAccept = () => {
    setShowWarning(false); 
    setShowTerms(true); 
  };

  const handleLogout = async () => {
    setLoading(true); 
  
    try {
      await updateTermsUser(false); 
      sessionStorage.clear(); 
      navigate("/login"); 
    } catch (error) {
      alert("Error al cerrar sesión:"); 
    } finally {
      setLoading(false); 
    }
  };
  
  useEffect(() => {
    const syncFilters = () => {
      setIsFiltersSynced(true);
    };
    syncFilters();
  }, []);

  useEffect(() => {
    setIsFiltersSynced(true); // Marca como sincronizado cada vez que se actualizan los filtros
  }, [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const filters = useMemo(() => ({
    productorPadreId: selectedParentProducerId,
    productorId: selectedProducerId,
    temporadaId: selectedSeasonId,
    especieId: selectedSpeciesId,
    variedadId: selectedVarietyId,
  }), [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const { dashboardData } = useDashboard(isFiltersSynced ? filters : null);

  let estimates:any = [];
  let segregations:any = [];
  let caliber:any = [];
  let productorNombre:any = "";

  const weeks:any[] = [];
  const segregacionData:any[] = [];
  const CurvaProduccionData:any[] = [];

  if(dashboardData.data){
    estimates = dashboardData.data.estimaciones;
    segregations = dashboardData.data.segregaciones;
    caliber = dashboardData.data.calibres;

    estimates.forEach((element:any)=> {
      productorNombre = estimates[0].productorNombre;

      weeks.push({
        name: element.semana,
        recepcionReal: element.totalKiloProceso,
        recepcionEstimada: element.totalKiloNeto,
      });
      weeks.push(element.semana)
    })

    segregations.forEach((element:any)=>{
      productorNombre = segregations[0].productorNombre;

      segregacionData.push({
        name:element.tipificacion,
        percent1:element.totalKilosAsociados,
        productorNombre: element.productorNombre
        // percent2: 66
      })
    })

    caliber.forEach((element:any)=>{
      productorNombre = caliber[0].productorNombre;

      CurvaProduccionData.push({
        name:element.tamano,
        percent1:element.totalKilosAsociados,
      })
    })
  }

  return (
    <div className="flex flex-col w-full h-full bg-white" style={{ fontFamily: "Cairo" }}>
      {showTerms && !hasAcceptedTerms && (
        <TermsConditions
          handleAccept={handleAccept} 
          handleReject={handleReject}
        />
      )}
        {showWarning && (
          <div className="warning-modal">
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
                  <div className="text-white flex flex-col items-center space-y-2">
                    <CogIcon className="w-12 h-12 animate-spin mt-[-10px]" />
                    <span className="text-lg font-semibold">Cargando...</span>
                  </div>
                </div>
              )}
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Advertencia</h2>
                </div>
                <div className="modal-body">
                  <p>
                    Para poder hacer uso del portal, debes aceptar los <b>Términos y Condiciones</b> de Uso. Si no las aceptas,
                    lamentablemente no podrás acceder a las funcionalidades del portal.
                  </p>
                  <p>
                    Si necesitas asistencia o tienes alguna duda, no dudes en contactarnos a través del siguiente correo: <br />
                    <b>info.productores@ddc.cl</b>
                  </p>
                  <p>¿Estás seguro de que deseas rechazar los términos y condiciones?</p>
                </div>
                <div className="modal-footer">
                <button className="buttonAccept" onClick={handleWarningAccept}>Aceptar</button>
                <button className="buttonCancel" onClick={handleLogout}>Rechazar</button>
                </div>
              </div>
            </div>
          )}

        <div className={`flex flex-wrap bg-[linear-gradient(90deg,_#01AAEF_0.72%,_#2C2D65_100%)] py-[23px] px-[35px] min-h-[181px]`}>
          <Navtab />
          <Filtros
            column={`nombrePadre`}
            onProducerChange={setSelectedProducerId}
            onParentProducerChange={setSelectedParentProducerId}
            onSeasonChange={setSelectedSeasonId}
            onSpeciesChange={setSelectedSpeciesId}
            onVarietyChange={setSelectedVarietyId}
          />
        </div>
        <div className={`px-6`}>
          <RowInfo rowData={dashboardData.balance} />
          <BarChart data={dashboardData.graficoRecepcion} productorNombre={productorNombre}/>
          <div className="curve-segregation flex flex-row flex-wrap gap-5">
              <SegregacionProduccion data={dashboardData.graficoSegregacion} productorNombre={productorNombre} />
              <CurvaProduccion data={dashboardData.graficoCalibre} productorNombre={productorNombre}/>
          </div>
          {/* <CurvaP roduccion /> */}
          {/* <Dropdown description="aaa" childrenOptions={[{name:'dafdfd', quantity:3454}]}/> */}
        </div>
      </div>
  )
}

export default Dashboard;