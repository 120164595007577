import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { callApi, decryptText } from '../../../services/utilservices';
import { useSnackbar } from '../../../context/SnackbarContext';
import axios from 'axios';
import Select from 'react-select';
import { CogIcon } from "@heroicons/react/20/solid";
import CryptoJS from 'crypto-js';
interface Producer {
  id: number;
  nombre: string;

}

const EditUser = () => {
  const { id } = useParams<{ id: string }>(); // Obtenemos el ID del usuario a editar
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [rut, setRut] = useState('');
  const [profileType, setProfileType] = useState<string>('');
  const [producerList, setProducerList] = useState<Producer[]>([]);
  const [selectedProducer, setSelectedProducer] = useState<Producer[]>([]);
  const navigate = useNavigate();
  const [data, setData] = useState<Producer[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const currentPage = 1; // Cambia esto si necesitas paginación
  const itemsPerPage = 10; // Cambia esto a la cantidad de ítems por página
  const [rutError, setRutError] = useState('');
  const { showMessage } = useSnackbar(); // mensaje 
  // Nuevos estados para las contraseñas
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const rutInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // Definición del mapeo de perfiles
  const profileMap: { [key: string]: string } = {
    "1": 'Administrador',
    "3": 'Productor',
    "2": 'Estandar',
  };

  const season = decryptText(sessionStorage.getItem('season') || "");
  let idseason = 0;
  if (season == "2024-2025") {
    idseason = 0;
  }
  else {
    idseason = 0;
  }
  useEffect(() => {
    // Cargar la lista de productores
    const fetchProducers = async () => {
      try {
        const result = await callApi({
          url: `Productor/1/3000/null/null/${idseason}`,
          method: 'GET',
        });

        const producerList = result.lista || [];
        setTotalCount(result.total || 0);

        if (Array.isArray(producerList)) {
          // Filtrar los productores donde esPadre sea true
          const filteredProducers = producerList.filter(producer => producer.esPadre === true);

          setData(filteredProducers);
          setProducerList(filteredProducers);
          fetchUser(filteredProducers); // Llama a fetchUser solo con los datos filtrados
        } else {
          console.error("Los datos recibidos no son un array:", producerList);
        }
      } catch (error) {
        console.error('Error al obtener la lista de productores:', error);
      }
    };

    fetchProducers();
  }, []);
  const validatePassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*.]/.test(password);
    const isLongEnough = password.length >= 8;

    if (!hasUpperCase) {
      return 'La contraseña debe contener al menos una letra mayúscula.';
    }
    if (!hasSpecialChar) {
      return 'La contraseña debe contener al menos un carácter especial.';
    }
    if (!isLongEnough) {
      return 'La contraseña debe tener al menos 12 caracteres.';
    }


  };


  const fetchUser = async (producerList: any) => {
    setLoading(true);
    try {
      const result = await callApi({
        url: `Usuarios/${id}`, // Cambia esto a la ruta correcta para obtener el usuario
        method: 'GET',
      });

      const user = result;
      // console.log(user)
      // console.log(result.data)
      setName(user.nombre || '');
      setEmail(user.correo || '');
      setPhone(user.telefono || '');
      setRut(user.rut || '');
      setProfileType(user.perfil);

      const userProductores = user.productores || [];
      //console.log(producerList)
      const selectedProducer = userProductores.map((prod: any) => ({
        id: prod,  // Crea un nuevo array solo con los productorId
      }));

      const selectedProducers = userProductores.map((prod: any) => {
        const productorDetails = producerList.find((producer: any) => producer.id === prod);

        // Si productorDetails se encuentra, devolvemos el objeto con id y nombre
        return productorDetails ? {
          id: prod,
          nombre: productorDetails.nombre,  // Retorna el nombre del productor
        } : null; // Si no se encuentra, retornamos null
      });

      // Filtra los nulls si no deseas tener elementos nulos en el resultado final


      // Asigna el resultado filtrado a `setSelectedProducer`
      setSelectedProducer(selectedProducers);

      console.log(selectedProducers);

    } catch (error) {
      console.error('Error al obtener los datos del usuario:', error);
    }
    finally {

      setLoading(false); // Desactivamos el spinner una vez la petición esté completa

    }
  };



  const validateRUT = (rut: any) => {
    const cleanRUT = rut.replace(/[^0-9kK]/g, '');




    // Bloquear RUTs con caracteres repetidos como 11.111.111-1
    const body = cleanRUT.slice(0, -1);
    if (/^(\d)\1+$/.test(body)) return false; // Verifica si todos los caracteres son iguales
    if (!/^\d+$/.test(body) || /^[1-9]$/.test(body)) return false;
    if (cleanRUT.length < 2) return false;

    const verifier = cleanRUT.slice(-1).toUpperCase();
    if (!/^\d+$/.test(body)) return false;

    let sum = 0;
    let multiplier = 2;

    for (let i = body.length - 1; i >= 0; i--) {
      sum += parseInt(body.charAt(i), 10) * multiplier;
      multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const remainder = sum % 11;
    const calculatedVerifier = remainder === 0 ? '0' : remainder === 1 ? 'K' : (11 - remainder).toString();

    return calculatedVerifier === verifier;
  };


  const formatRUT = (rut: any) => {
    const cleanRUT = rut.replace(/[^0-9kK]/g, '');
    if (!cleanRUT) return '';

    const body = cleanRUT.slice(0, -1);
    const verifier = cleanRUT.slice(-1).toUpperCase(); // Asegúrate de que el verificador esté en mayúsculas

    // Formato del cuerpo con puntos
    const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${formattedBody}-${verifier}`;
  };

  const handleRUTChange = (e: any) => {
    const value = e.target.value.toUpperCase(); // Convertir el valor a mayúsculas
    const formattedRUT = formatRUT(value);
    setRut(formattedRUT);

    // Validar el RUT
    if (validateRUT(formattedRUT)) {
      setRutError(''); // Limpiar el mensaje de error si es válido
    } else {
      setRutError('RUT no válido'); // Mensaje de error si no es válido
    }
  };

  const handleRUTBlur = () => {
    // Validar al perder el foco
    if (!validateRUT(rut)) {
      setRutError('RUT no válido');
      rutInputRef.current?.focus(); // Forzar el foco de nuevo en el input
    }
  };
  const hashPassword = (password: any) => {
    return CryptoJS.SHA256(password).toString(); // Hash SHA256
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //console.log(name, email, rut, phone, selectedProducer);

    // Verifica si todos los campos requeridos están completos
    if (!name || !email) {
      showMessage('Completa todos los campos', 'error');
      return;
    }


    if (selectedProducer.length == 0 && profileType == "3") {
      showMessage('Debe seleccionar un productor', 'error');
      return;
    }


    if (password) {
      const passwordValidationResult = validatePassword(password);

      if (typeof passwordValidationResult === 'string') {
        // Si validatePassword devuelve un mensaje de error
        showMessage(passwordValidationResult, 'error');
        //setPasswordError(passwordValidationResult);
        return;
      }
      if (password != confirmPassword) {
        showMessage('Las contraseñas no coinciden', 'error');
        //setPasswordError('Las contraseñas no coinciden');
        return;
      }
    }


    // Si no hay errores, puedes limpiar el mensaje de error o proceder
    setPasswordError('');
    const profileTypeValue = profileMap[profileType as keyof typeof profileMap]; // Asegúrate de que `profileMap` esté correctamente definido fuera de la función
    // console.log(profileType,profileTypeValue)
    if (!profileTypeValue) {
      showMessage('Tipo de perfil no válido', 'error');
      return;
    }
    if (rut && !validateRUT(rut)) {
      setRutError('RUT no válido');
    }
    else {
      setRutError('');
    }

    const userPayload = {

      id: id,
      nombre: name,
      correo: email,
      telefono: phone,
      rut: rut,
      perfil: profileType,
      contrasena: password ? hashPassword(password) : "", // Encriptar si no está vacía
      confirmaContrasena: confirmPassword ? hashPassword(confirmPassword) : "",
      productores: selectedProducer.map((producer) => producer.id),

    };

    try {
      setLoading(true);
      const response = await callApi({
        url: `Usuarios`, // Cambia esto a la ruta correcta para editar el usuario
        method: 'PUT',
        data: userPayload,
      });

      if (response) {
        showMessage('Usuario editado exitosamente', 'success');
      }

      setTimeout(() => {
        navigate('/administracion/usuarios');
      }, 2000);
    } catch (error) {
      showMessage('Ha ocurrido algun error al editar', 'error');
    }
    finally {

      setLoading(false); // Desactivamos el spinner una vez la petición esté completa

    }
  };

  const handleClose = () => {
    navigate(`/administracion/usuarios/`);
  };
  const options = producerList.map(producer => ({
    value: producer.id,
    label: producer.nombre,
  }));

  useEffect(() => {
    setIsValid(true);
    if (rut && !validateRUT(rut)) {
      setRutError('RUT no válido');
    }
    else {
      setRutError('');
    }
  }, [rut]);


  const handlePhoneChange = (e: any) => {
    const input = e.target.value;

    // Validar que no sea una secuencia repetida (ej. 111111111, 222222222)
    if (/^(\d)\1{8}$/.test(input)) {
      showMessage('El número de teléfono no puede ser una secuencia repetida', 'error');
      setIsValid(false);
      console.log(isValid)
      return;
    }

    // Permitir solo números y limitar a 9 caracteres
    if (/^\d{0,9}$/.test(input)) {
      setPhone(input);
      setPhoneError('');
      setIsValid(true);
    }
  };
  return (
    <div className="w-full p-6 mb-24">
      <div className="max-w-[1009px] max-h-[553px] h-full w-full px-[36px] pt-[26px] pb-[40px] shadow rounded-lg">
        <h2 className="text-2xl font-semibold text-[#111928] page-title">Editar Usuario</h2>
        <p className="text-gray-500 mt-2 page-subtitle">
          Modifica los datos del usuario.
        </p>

        <div className="mt-[22px]">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Nombre Completo</label>
                <input
                  type="text"
                  placeholder="Ingresa el nombre y apellido del usuario"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="outline-0 w-full max-w-[338px] h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">E-mail</label>
                <input
                  type="email"
                  placeholder="Ingresa la dirección de correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                  className="outline-0 w-full max-w-[338px] h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Número celular</label>
                <div className="flex">
                  <select className="h-[42px] px-3 py-2 border rounded-l">
                    <option value="+56">+56</option>
                  </select>
                  <input
                    type="text"
                    placeholder="965893546"
                    value={phone}
                    maxLength={9}
                    onChange={handlePhoneChange}
                    className={`outline-0 w-full h-[42px] px-3 py-[9px] border rounded-[6px] ${!isValid ? 'border-red-500' : ''}`}
                  />
                </div>
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">RUT</label>
                <input
                  type="text"
                  placeholder="Ingresa el RUT del usuario"
                  value={rut}
                  ref={rutInputRef}
                  maxLength={12}
                  onBlur={handleRUTBlur}
                  onChange={handleRUTChange}
                  className="outline-0 w-full max-w-[338px] h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
                {rutError && <p className="text-red-500 text-sm">{rutError}</p>}
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Contraseña</label>
                <input
                  type="password"
                  placeholder="Ingresa una contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Confirmar Contraseña</label>
                <input
                  type="password"
                  placeholder="Confirma tu contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
                {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
              </div>

            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Tipo de perfil</label>
                <select
                  value={profileType}

                  onChange={(e) => setProfileType(e.target.value)


                  }
                  className="outline-0 w-full max-w-[338px] h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                >
                  <option value="">Seleccione</option>
                  <option value="1">Administrador</option>
                  <option value="3">Productor</option>
                  <option value="2">Estandar</option>
                </select>
              </div>
              {profileType == "3" && (
                <div>
                  <label className="block mb-4 roboto-regular text-[16px] text-[#292D32]">Productor</label>
                  <Select
                    isMulti
                    id="producer"
                    value={
                      Array.isArray(selectedProducer)
                        ? selectedProducer
                          .filter(producer => producer !== null) // Filtrar los valores nulos
                          .map(producer => ({
                            value: producer.id,
                            label: producer.nombre,
                          }))
                        : []
                    }
                    options={options}
                    onChange={(newValues) => {
                      //console.log(selectedProducer)
                      const selectedProducers = newValues
                        ? newValues.map((item) => ({
                          id: item.value,
                          nombre: item.label,
                        }))
                        : []; // Si newValues es nulo, se asigna un array vacío

                      setSelectedProducer(selectedProducers); // Asegúrate de que este es un array
                    }}
                    isClearable
                    placeholder="Selecciona un productor"
                  />


                </div>
              )}
            </div>

            <div className="flex justify-end mt-4 mb-12 relative">
              <button
                type="button"
                onClick={handleClose}
                className="px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded hover:bg-blue-50 w-[196px] h-[42px]"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 w-[196px] h-[42px]"
                disabled={!isValid}
              >
                Editar
              </button>
              <br></br>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
