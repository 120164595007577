import Login from './modules/login';
import Recovery from './modules/recovery';
import Dashboard from "./modules/dashboard";
import Reception from "./modules/reception";
import Process from "./modules/processes";
import Reports from "./modules/reports";
import Producer from "./modules/producers";
import User from "./modules/users";
import Profile from "./modules/profile";
import CreateUser from "./modules/users/create";
import EditUser from "./modules/users/edit";
import ListUser from "./modules/users/List";
import Administration from "./modules/administration";
import ListProducer from "./modules/producers/list";
import CreateProducer from "./modules/producers/create";
import { Navigate } from 'react-router-dom';
import AccessRequest from './modules/AccessRequest';
import AuthGuard from './guards/AuthGuard';
import PrivateRoute from "./guards/PrivateRoute";
import PreguntasFrecuentes from "./modules/components/ayuda";
import AdminPrivateRoute from './guards/AdminPrivateRoute';
interface RouteConfig {
  name: string;
  path: string;
  Component: React.FC;
  children?: RouteConfig[];
}

export const routes: RouteConfig[] = [
  { name: 'Login', path: '/login', Component: () =>(<AuthGuard><Login/></AuthGuard>) },
  { name: 'Solicitar Acceso', path: '/AccessRequest', Component: () => (<AuthGuard><AccessRequest/></AuthGuard>) },
  { name: 'Recuperar Acceso', path: '/recovery', Component: () => (<AuthGuard><Recovery/></AuthGuard>) },
  { name: 'Dashboard', path: '/dashboard', Component: () => (<PrivateRoute><Dashboard/></PrivateRoute>) },
  { name: 'Recepciones', path: '/recepciones', Component: () => (<PrivateRoute><Reception/></PrivateRoute>) },
  { name: 'Procesos', path: '/procesos', Component: () => (<PrivateRoute><Process/></PrivateRoute>) },
  { name: 'Reportes', path: '/reports', Component: () => (<PrivateRoute><Reports/></PrivateRoute>) },
  { name: 'Perfil', path: '/perfil', Component: () => (<PrivateRoute><Profile/></PrivateRoute>) },
  { name: 'Preguntas Frecuentes', path: '/preguntasfrecuentes', Component: () => (<PrivateRoute><PreguntasFrecuentes/></PrivateRoute>) },
  {
    name: 'Administracion',
    path: '/administracion',
    Component: () => (<AdminPrivateRoute><Administration/></AdminPrivateRoute>),
    children: [
      {
        name: 'Usuarios',
        path: 'usuarios',
        Component: User,
        children: [
          { name: '', path: '', Component: ListUser },
          { name: 'Nuevo Usuario', path: 'crear', Component: CreateUser },
          { name: 'Editar Usuario', path: 'editar/:id', Component: EditUser }
        ]
      },
      {
        name: 'Productores',
        path: 'productores',
        Component: Producer,
        children: [
          { name: '', path: '', Component: ListProducer },
          { name: 'Nuevo Productor', path: 'crear', Component: CreateProducer }
        ]
      },
    ]
  },  
  { name: 'Root', path: '/', Component: () => <Navigate to="/login" replace /> },
  { name: 'NotFound', path: '*', Component: () => <Navigate to="/login" replace /> },
];
